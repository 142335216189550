import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchText: '',
  sortByBrand: [],
  sortItem: '',
};

const FilterSearchSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    navSearch: (state, action) => {
      state.searchText = action.payload.searchText;
    },
    sortByBrand: (state, action) => {
      state.sortByBrand = action.payload.sortByBrand;
    },
    sortItem: (state, action) => {
      state.sortItem = action.payload.sortItem;
    },
    clearSort: (state, action) => {
        state.searchText = '';
      state.sortByBrand = [];
    state.sortItem = '';
    },
  },
});

export const { action, sortItem,  sortByBrand, navSearch,  } = FilterSearchSlice.actions;
export default FilterSearchSlice.reducer;
