import React from "react";
//import { withRouter } from 'react-router'
// import GetStarted from "./components/GetStarted";
// import HowToUse from "./components/HowToUse";
import { Link } from "react-router-dom";
import Banner from "../components/Banner/Banner";
import Footer from "../components/Footer/Footer";
import NewsAdvertisement from "../components/NewsAdvertisement/NewsAdvertisement";
import PriceComparisonShowCase from "../components/PriceComparisonShowCase/PriceComparisonShowCase";
import Showcase from "../components/Showcase/Showcase";
import Testimonial from "../components/Testimonial/Testimonial";
import "./Main.css";


const Main = () => {
  return (
    <>
      <div className="fmcg-landing-page-container">
        <div
          className="top-header"
          style={{ background: "#022D33", height: "5px", width: "100vw" }}
        />
        {/* <div className="top-header-announcement">
            <div className="announcement-text">
              ALERT: We're sorry, the SmartCart App is currently down for maintenance. We're working on it right now and
              will be back soon. Thanks for your patience.
            </div>
          </div> */}
        <div className="header">
          <div className="header-left mt-8">
            <Link className="header-left-company-name " to="/">
              SmartCarts
            </Link>
            {/* <div className="header-left-company-name ">
                SmartCart
              </div> */}
          </div>
        </div>
        <div className="typewriter-container">
            <Banner />
          </div>
        <div className="banner--fadeBottomBanner" />
        {/* <div className="getstarted-title">How to get started</div>
          <div className="getstarted-container">
            <GetStarted />
          </div>
          <div className="howToUse-container">
            <HowToUse />
          </div> */}

        <div className="showcase-container">
            <Showcase />
          </div>
          <div className="testimonial-container">
            <Testimonial />
          </div>
          <div className="news-advertisement-container">
            <NewsAdvertisement />
          </div>
          <div className="price-comparison-showcase-container">
            <PriceComparisonShowCase />
          </div>
          <div className="footer-container">
            {/* <GeneralFooter /> */}
            <Footer></Footer>
          </div>
      </div>
    </>
  );
};

export default Main;
