import { apiSlice } from "../Api/ApiSlice";

export const getSearchedProductName = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // products api
    getSearchedProducts: builder.query({
        query: (searchText) => `products/getSearchedProductName?searchText=${searchText}`,
    
        // async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        //   try {
        //     const response = await queryFulfilled;
        //   } catch (error) {}
        // },
      }),
  }),
});

export const {useGetSearchedProductsQuery} = getSearchedProductName;