import React from "react";
import { MoonLoader } from "react-spinners";
import { useGetPriceHistoryQuery } from "../../../features/PricingGraph/PricingGraphApi";
import PriceChart from "./PriceChart";

const PriceChartAndDescription = ({ product }) => {
  let productId = product?.targetProductId;

  // Find the object with the highest match score
  const getMatchStoreProduct = (storeFrom) => {
    const highestMatchScoreObject =
      storeFrom?.length > 0
        ? storeFrom?.reduce((prev, current) => {
            return prev.matchScore > current.matchScore ? prev : current;
          })
        : null;
    return highestMatchScoreObject;
  };

  const colesId = getMatchStoreProduct(
    product?.colesProducts
  )?.candidateProductId;
  const amazonId = getMatchStoreProduct(
    product?.amazonProducts
  )?.candidateProductId;

  const {
    data: pricingHistory,
    isError,
    isLoading,
    isSuccess,
  } = useGetPriceHistoryQuery({
    productId: productId,
    colesId: colesId,
    amzId: amazonId,
  });

  if (isLoading) {
    return <MoonLoader color="#36d7b7" margin={5} size={20} speedMultiplier={1} />
    // <h3 className="text-3xl font-bold text-center mt-20">Loading..</h3>;
  }

  return (
    <>
      <section
        style={{ fontFamily: "'Roboto', sans-serif" }}
        class="text-gray-600 body-font relative mt-20"
      >
        <div class="container px-5 py-6 mx-auto flex sm:flex-nowrap flex-wrap gap-8">
          <div className="md:w-3/5 w-full md:py-8 mt-8 md:mt-0 ">
            <h2 className="text-3xl text-[#191919] font-medium">Price track</h2>
            <hr class="h-px mt-8 mb-16 bg-gray-200 border-0 dark:bg-gray-700"></hr>

            {isSuccess && (
              <PriceChart pricingHistory={pricingHistory}></PriceChart>
            )}

            {isError && !isSuccess && (
              // <h3 className="bg-red-400 px-3 py-2 rounded-md text-center">
              //   Apologies, something went wrong! try again later.
              // </h3>
              <h3 className="text-3xl font-bold text-center mt-20">Loading..</h3>   
            )}

            {/* ------checkbox-------- */}
            <div className="flex items-center justify-center mt-8">
              <div class="flex items-center mr-4">
                <input
                  checked
                  id="red-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-[#2B3FF2] bg-[#a5aeff] border-[#6e7ae3] rounded focus:ring-[#2B3FF2] focus:ring-2 "
                />
                <label
                  for="red-checkbox"
                  class="ml-2 font-medium text-gray-900 dark:text-gray-300 text-lg"
                >
                  Amazon
                </label>
              </div>
              <div class="flex items-center mr-4">
                <input
                  checked
                  id="green-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-[#8EE27E] bg-[#c9e6c3] border-[#8EE27E] rounded focus:ring-[#8EE27E] focus:ring-2 "
                />
                <label
                  for="green-checkbox"
                  class="ml-2 font-medium text-gray-900 dark:text-gray-300 text-lg"
                >
                  Woolies
                </label>
              </div>
              <div class="flex items-center mr-4">
                <input
                  id="purple-checkbox"
                  type="checkbox"
                  value="#FFD84F"
                  class="w-4 h-4 text-[#FFD84F] bg-[#fbedb9] border-[#FFD84F] rounded focus:ring-[#FFD84F] focus:ring-2"
                />
                <label
                  for="purple-checkbox"
                  class="ml-2 font-medium text-gray-900 dark:text-gray-300 text-lg"
                >
                  Coles
                </label>
              </div>
            </div>
          </div>

          {/* --------------- description------------ */}

          {product?.product_description && (
            <div class=" md:w-3/5 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
              <h2 className="text-3xl text-[#191919] font-medium">
                Description
              </h2>
              <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
              <div class="leading-relaxed">
                <p className="text-lg text-[#191919]">
                  {product?.product_description}
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default PriceChartAndDescription;
