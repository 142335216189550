import { apiSlice } from "../Api/ApiSlice";

export const getAllProducts = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // products api
    getAllProducts: builder.query({
        query: ({page,taxonomyId, productSort, limit, excludeProductId}) => `products/getProductsData?page=${page}&taxonomyId=${taxonomyId}&productSort=${productSort? productSort: ""}&limit=${limit}&excludeProductId=${excludeProductId ? excludeProductId : 0}`,


        // query: ({ page, taxonomyId, productSort, limit, excludeProductId }) => {
        //   // Initialize an empty array to store query parameters
        //   const queryParams = [];
      
        //   // Add parameters to the array if they exist
        //   if (page) queryParams.push(`page=${page}`);
        //   if (taxonomyId) queryParams.push(`taxonomyId=${taxonomyId}`);
        //   if (productSort) queryParams.push(`productSort=${productSort}`);
        //   if (limit) queryParams.push(`limit=${limit}`);
        //   if (excludeProductId) queryParams.push(`excludeProductId=${excludeProductId}`);
      
        //   // Construct the final query string by joining the parameters with '&'
        //   const queryString = queryParams.join('&');
      
        //   // Return the complete URL with the query string
        //   return `products/getProductsData${queryString ? `?${queryString}` : ''}`;
        // },

    
        // async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        //   try {
        //     const response = await queryFulfilled;
        //   } catch (error) {}
        // },
      }),
  }),
});

export const {useGetAllProductsQuery} = getAllProducts;