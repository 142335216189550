import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetSearchedProductsQuery } from "../../../features/SearchProductName/SearchProductNameApi";

const SearchSuggestion = ({ setSearchDropdown, searchDropdown }) => {
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const searchText = useSelector((state) => state.FilterSearchSlice.searchText);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearch(searchText);
    }, 300); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchText]);

  const { data: products, isLoading } =
    useGetSearchedProductsQuery(debouncedSearch); // Use debouncedSearch instead of searchText

  useEffect(() => {
    if (products?.length) {
      setSearchDropdown(true);
    }
  }, [products, setSearchDropdown]);

  return (
    <>
      {products?.length > 0 && searchDropdown && (
        <div className="bg-gradient-to-r from-[#02C39A] to-[#05668D] rounded-lg p-[1px]">
          <div class="relative md:overflow-x-auto overflow-x-scroll rounded-lg md:w-full w-72  bg-white ">
            <table class="w-full text-sm text-left text-gray-500">
              <tbody>
                {!isLoading &&
                  products?.length > 0 &&
                  products.map((product, index) => {
                    return (
                      <tr
                        key={index}
                        class="border-b cursor-pointer border-[#F0F0F0] "
                      >
                        <th
                          scope="row"
                          class="px-3 py-[10px] text-sm font-normal text-black whitespace-nowrap bg-[#FFFFFF] flex items-center gap-1"
                        >
                          {/* <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10 2.75a7.25 7.25 0 015.63 11.82l4.9 4.9a.75.75 0 01-.98 1.13l-.08-.07-4.9-4.9A7.25 7.25 0 1110 2.75zm0 1.5a5.75 5.75 0 100 11.5 5.75 5.75 0 000-11.5z"></path></svg> */}
                          <Link onClick={() => setSearchDropdown(false)} to={`/product/${product.product_id}`}>
                            {product.product_name}
                          </Link>
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      
    </>
  );
};

export default SearchSuggestion;
