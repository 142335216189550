import { apiSlice } from "../../Api/ApiSlice";

export const getSimilarProducts = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // products api
    getSimilar: builder.query({
        query: ({productId, candidateStore}) => `products/getCartSimilarProduct?productId=${productId}&candidateStore=${candidateStore}`,
    
        // async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        //   try {
        //     const response = await queryFulfilled;
        //   } catch (error) {}
        // },
      }),
  }),
});

export const {useGetSimilarQuery} = getSimilarProducts;