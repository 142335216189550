import { configureStore } from '@reduxjs/toolkit';
import AllProductsSlice from '../features/AllProducts/AllProductsSlice';
import { apiSlice } from '../features/Api/ApiSlice';
import authSliceReducer from "../features/Auth/AuthSlice";
import cartReducer from '../features/Cart/CartSlice';
import FilterSearchSlice from '../features/FilterSearch/FilterSearchSlice';
import SidebarOnOffSlice from '../features/Utils/SidebarOnOffSlice';
import sortOnOffSlice from '../features/Utils/sortOnOffSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath] : apiSlice.reducer,
    auth: authSliceReducer,
    FilterSearchSlice: FilterSearchSlice,
    SidebarStatus: SidebarOnOffSlice,
    SortStatus: sortOnOffSlice,
    cart: cartReducer,
    products: AllProductsSlice
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  }
});
