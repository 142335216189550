import React, { useState } from "react";
import infoIcon from "../../assets/icons/Vector.svg";
import cartIcon from "../../assets/icons/cartIcon.svg";
import coponIcon from "../../assets/icons/copon.png";
import MobileCartInfoModal from "../Cart/MobileCartInfoModal";

const BrandCart = ({ brandImg, WooloworthsTotalPrice, colesTotalPrice, amazonTotalPrice}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="py-2 flex justify-center w-full">
      <img onClick={() => setOpen(true)} className="absolute top-5 left-10 md:hidden" src={infoIcon} alt="" />
      <MobileCartInfoModal
        open={open} setOpen={setOpen}
      ></MobileCartInfoModal>
      <div className="flex flex-col w-full p-4 rounded-3xl bg-[#FFF8E7] md:bg-[#FFFFFF]">
        <div className="md:bg-[#F5F5F5]  md:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:p-4 rounded-3xl">
          <div class="h-full md:bg-[#F5F5F5] w-full flex items-center md:justify-between justify-evenly">
            <img
              alt="team"
              class="xl:w-20 xl:h-20 md:w-16 w-14 object-center  "
              src={brandImg}
            />

            <div class="flex-shrink flex flex-col gap-6 ">
              <div className="flex md:justify-center xl:gap-3 md:gap-1 gap-2 items-start">
                <img className="xl:w-full w-8 " src={cartIcon} alt="" />
                <div className="flex flex-col gap-2">
                  <h2 class="md:text-[#191919] text-black title-font xl:text-[32px] text-2xl font-bold px-1">
                    ${WooloworthsTotalPrice ? WooloworthsTotalPrice : colesTotalPrice ? colesTotalPrice : amazonTotalPrice ? amazonTotalPrice : '00.0'}
                  </h2>
                  <div className="xl:min-w-[106px] md:min-w-[80px]">
                    <p className="text-xs text-black">Total items: 34</p>
                    <p className="text-xs text-black">Unavailable items: 8</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-3 md:block hidden">
            <div className="">
              <p className="text-xs text-[#B5ABAB]">Click & Collect: No</p>
              <p className="text-xs text-[#B5ABAB]">
                Delivery from:{" "}
                <span className="font-semibold">Free with Prime</span>{" "}
              </p>
              <div className=" gap-2 flex justify-start mt-1">
                <img src={coponIcon} alt="" />
                <p className="text-xs text-[#B5ABAB]">3 Codes</p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mx-auto mt-3 ">
          <button className="hover:bg-gradient-to-r from-[#1897c5] to-[#05dfb3] hover:bg-[#02C39A] border border-[#02C39A] rounded-3xl  text-black text-xs font-bold py-3  px-16 transition-all ease-in duration-100 shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
            Go to site
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandCart;
