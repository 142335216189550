import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleSidebar } from "../../../../features/Utils/SidebarOnOffSlice";
import "./Dropdown.css";

const Dropdown = ({ mainTitle, mainItems, isOpen, onDropdownClick }) => {

  const dispatch = useDispatch();

  return (
    <>
      <ul class="tree">
        <li>
          {/* <input type="checkbox" checked={drop} id="c2" /> */}
          <label onClick={onDropdownClick} for="c2" class="tree_label bg-[#F5EFEB] text-lg text-black">
            {mainTitle}
            {mainItems?.length > 0 ? (
              <svg
                className={`w-4 h-4 ml-2 transition-transform duration-300 transform inline ${
                  !isOpen ? "rotate-180" : ""
                }`}
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            ) : null}
          </label>

          <ul className="ml-10">
            {isOpen
              ? mainItems.map((item,index) => {
                if (!item) {
                  return null
                }
                const categoryName = item.split('_')[0]
                // const taxonomyId = item.split('-')[1]
                  return (
                    <li key={index}>
                      <Link to={`/${mainTitle}/${item}`} class="tree_label text-[#24222081] font-semibold"><button onClick={() => dispatch(toggleSidebar())} className=" text-start  focus:text-black  focus:rounded-lg focus:px-3 focus:py-1 focus:mx-[-0.5em] focus:bg-[#F5EFEB] active:bg-[#F5EFEB]">{categoryName}</button></Link>
                    </li>
                  );
                }) 
              : null}
          </ul>
        </li>
      </ul>
    </>
  );
};

export default Dropdown;
