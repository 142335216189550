import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../features/Utils/SidebarOnOffSlice";

const Blocks = () => {

  const dispatch = useDispatch();

  const isSidebarOn = useSelector(state => state.SidebarStatus.IsSidebarOn);

  return (
    <>
      <div className="hidden container mx-auto md:flex justify-between items-center my-5 ">
        <button
          onClick={() => dispatch(toggleSidebar())}
          type="button"
          class="text-black hidden md:block border font-medium rounded-2xl text-sm px-5 py-2.5 text-center mx-2 border-[#02c399ec] hover:bg-gradient-to-r from-[#1897c5] to-[#05dfb3] hover:text-white transition-all ease-in duration-75 bg-gray-200 w-40"
        >
          {!isSidebarOn ? " Show Category" : "Hide Category"}
        </button>

        {/* ------------------------ */}
        {/* <svg
          className={`${
            !isOpen ? " translate-y-0" : "translate-y-full pt-[18px]"
          } ml-3 md:hidden block transition-all duration-100 ease-in`}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_12_47531)">
            <path
              d="M1.3728 9.87035H10.6519M1.3728 4.15283H18.5253M1.3728 15.5879H18.5253"
              stroke="#1B153D"
              stroke-width="1.55932"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_12_47531">
              <rect
                width="18.7119"
                height="18.7119"
                fill="white"
                transform="translate(0.593262 0.254395)"
              />
            </clipPath>
          </defs>
        </svg> */}

        {/* --------------------------- */}

        <div className="flex justify-center items-center gap-4">
          {/* <button
            type="button"
            class="text-black border font-medium rounded-xl text-sm px-5 py-2.5 text-center mr-2 border-[#02C39A] hover:bg-gradient-to-r from-[#1897c5] to-[#05dfb3] hover:text-white transition-all ease-in duration-75 md:block hidden"
          >
            Specials
          </button>
          <button
            type="button"
            class="text-black border font-medium rounded-xl text-sm px-5 py-2.5 text-center mr-2 border-[#02C39A] hover:bg-gradient-to-r from-[#1897c5] to-[#05dfb3] hover:text-white transition-all ease-in duration-75 md:block hidden"
          >
            Categories
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Blocks;
