import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { isMobileDevice } from "../Others/utility";
import "./getStartedButton.css";

const GetStartedButton = () => {
    return (
        <div>
        {isMobileDevice() ? (
          <Link to="/">
            <Button
              style={{ width: "270px", height: "45px", borderRadius: "32px" }}
              className="banner-left-add-button-desktop"
              primary
            >
              <Button.Content
                className="banner-add-to-chrome-button text-white"
                style={{}}
                visible
              >
                {"START COMPARING"}
              </Button.Content>
            </Button>
          </Link>
        ) : (
          <Link to='/'>
          
          <Button
            // onClick={() =>
            //   window.open(
            //     "https://chrome.google.com/webstore/detail/smartcart/hkckdnfadmnkgmkojfhdihollnjhlaam"
            //   )
            // }
            style={{ width: "270px", height: "45px", borderRadius: "32px" }}
            className="banner-left-add-button-desktop"
            primary
          >
            <Button.Content
              className="banner-add-to-chrome-button text-white"
              style={{}}
              visible
            >
              {"Start Comparing"}
            </Button.Content>
          </Button>
          </Link>
        )}
      </div>
    );
};

export default GetStartedButton;