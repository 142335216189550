import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import upArrowSvg from "../../assets/icons/arrowup2.svg";
import downArrowSvg from "../../assets/icons/down-arrow.svg";
import MinusIcon from "../../assets/icons/minusIcon.png";
import PlusIcon from "../../assets/icons/plusIcon.png";
import { addToCart, decreaseQuantity } from "../../features/Cart/CartSlice";

import SimilarCartCarousel from "./SimilarCartCarousel";

const ProductCart = ({
  product,
  WooloworthsProduct,
  ParentProduct,
  colesProduct,
  amazonProduct,
}) => {
  const dispatch = useDispatch();

  const woolworthParentProduct = useSelector((state) =>
    state.cart.items.find(
      (pro) => pro.targetProductId === WooloworthsProduct?.parentId
    )
  );

  const colesParentProduct = useSelector((state) =>
    state.cart.items.find(
      (pro) => pro.targetProductId === colesProduct?.parentId
    )
  );
  const amazonParentProduct = useSelector((state) =>
    state.cart.items.find(
      (pro) => pro.targetProductId === amazonProduct?.parentId
    )
  );

  const quantity =
    woolworthParentProduct?.woolworth?.quantity ||
    colesParentProduct?.coles?.quantity ||
    amazonParentProduct?.amazon?.quantity || ParentProduct?.quantity;


  const [item, setItem] = useState(quantity);
  const [isSimilar, setIsSimilar] = useState(false);

  const woolsSimilarProduct = woolworthParentProduct?.woolWorthProducts;
  const colesSimilarProduct = colesParentProduct?.colesProducts;
  const amazonSimilarProduct = amazonParentProduct?.amazonProducts;


  // increment and decrement handler for quantity
  const handleDecrementToCart = (mainProduct) => {
    if (mainProduct?.WooloworthsProduct?.parentId) {
      const productId = mainProduct.WooloworthsProduct.parentId;
      dispatch(decreaseQuantity({productId}));
    }
    else if (mainProduct?.colesProduct?.parentId){
      const productId = mainProduct.colesProduct.parentId;
      const shop = "coles"
      dispatch(decreaseQuantity({productId, shop}));
    }
    else if (mainProduct?.amazonProduct?.parentId){
      const productId = mainProduct.amazonProduct.parentId;
      const shop = "amazon"
      dispatch(decreaseQuantity({productId, shop}));
    }
  };

  
  // handle increment
  const handleIncrementToCart = (mainProduct) => {
    if (mainProduct?.woolworthParentProduct) {
      const product = mainProduct.woolworthParentProduct;
      dispatch(addToCart({product}));
    }
    else if (mainProduct?.colesParentProduct){
      const product = mainProduct.colesParentProduct;
      const shop = "coles"
      dispatch(addToCart({product, shop}));
    }
    else if (mainProduct?.amazonParentProduct){
      const product = mainProduct.amazonParentProduct;
      const shop = "amazon"
      dispatch(addToCart({product, shop}));
    }
    // dispatch(addToCart({product, shop}));
  };


  useEffect(() => {
    setItem(
      woolworthParentProduct?.woolworth?.quantity ||
    colesParentProduct?.coles?.quantity ||
    amazonParentProduct?.amazon?.quantity || ParentProduct?.quantity
    );
  }, [
    woolworthParentProduct?.woolworth?.quantity,
    colesParentProduct?.coles?.quantity,
    amazonParentProduct?.amazon?.quantity,
    ParentProduct?.quantity
  ]);


  let isSimilarExist = false;
  if (
    colesSimilarProduct?.length > 0 ||
    amazonSimilarProduct?.length > 0 ||
    woolsSimilarProduct?.length > 0
  ) {
    isSimilarExist = true;
  } else {
    isSimilarExist = false;
  }

                  // || WooloworthsProduct?.parentId  colesProduct?.parentId ||
                  //   amazonProduct?.parentId

  // let IncrementQuantityStore;
  // let storeForDecrementQuantity;

  // if (woolworthParentProduct ) {
  //   IncrementQuantityStore = { woolworthParentProduct, wools: true };

  // } else if (colesParentProduct) {
  //   checkStore = { colesParentProduct, coles: true };
  // } else if (colesParentProduct) {
  //   checkStore = { amazonParentProduct, amazon: true };
  // }


  if (
    !WooloworthsProduct?.candidateProductId &&
    !colesProduct?.candidateProductId &&
    !amazonProduct?.candidateProductId && !ParentProduct?.quantity
  ) {
    return (
      <div className="flex justify-center items-center">
        <div
          class={`flex justify-center items-center ${
            isSimilar && "bg-[#FFF8E7]"
          }`}
        >
          <div class="max-w-md w-full mb-[4px] overflow-hidden py-2  xl:py-3 px-3">
            <div class="flex md:justify-between items-start lg:gap-1 xl:gap-2 gap-2 h-[107px] xl:h-[102px]">
              <h2 className="text-center">unavailable</h2>
            </div>
          </div>
        </div>
        <hr className="bg-[#F5F5F5]" />
      </div>
    );
  }

  return (
    <div>
      <div
        class={`flex justify-center items-center ${
          isSimilar && "bg-[#FFF8E7]"
        }`}
      >
        <div class="max-w-md w-full mb-[4px] overflow-hidden py-2  xl:py-3 px-3">
          <div class="flex md:justify-between items-start lg:gap-1 xl:gap-2 gap-2 h-14">
            <div class="md:flex-shrink-0 xl:w-20 md:w-16 w-32 md:max-h-20">
              <img
                class="max-h-20"
                src={
                  WooloworthsProduct
                    ? WooloworthsProduct.candidateProductUrl
                    : colesProduct
                    ? colesProduct.candidateProductUrl
                    : amazonProduct ? amazonProduct.candidateProductUrl : ParentProduct.targetProductUrl
                }
                alt="img"
              />
            </div>
            <div class=" md:flex-grow">
              <p class="block mt-1 md:text-xs xl:text-sm leading-5 tracking-[-0.41px] font-bold text-black ">
                {/* Macro Chicken Thigh Fillets Free Range 380g - 750g */}
                {WooloworthsProduct?.cadidateName?.slice(0, 45)}
                {colesProduct?.cadidateName?.slice(0, 45)}
                {amazonProduct?.cadidateName?.slice(0, 45)}
                {ParentProduct?.targetName}...
              </p>
            </div>
            <div class="flex flex-col justify-between items-end gap-4">
              <p class=" text-black xl:text-xl md:text-lg text-xl font-bold">
                {/* $19.99 */}$
                {woolworthParentProduct
                  ? (
                    woolworthParentProduct?.woolworth?.currentPrice *
                      woolworthParentProduct?.woolworth?.quantity
                    ).toFixed(2)
                  : colesParentProduct
                  ? (
                    colesParentProduct?.coles?.currentPrice * colesParentProduct?.coles?.quantity
                    ).toFixed(2)
                  : amazonParentProduct ?
                    (
                      amazonParentProduct?.amazon?.currentPrice * amazonParentProduct?.amazon?.quantity
                    ).toFixed(2) : ParentProduct && (ParentProduct.currentPrice * ParentProduct.quantity).toFixed(2)}
                    {/* colesParentProduct?.coles?.quantity ||
    amazonParentProduct?.amazon?.quantity */}
                {/* ${(product?.currentPrice * product?.quantity).toFixed(2)} */}
              </p>
            </div>
          </div>
          {/* <p class="text-[11px] xl:text-sm text-black ml-[80px] md:ml-[70px] xl:ml-[90px] xl:mt-[-10px]">
            Macro
          </p> */}
          <div class="flex items-center justify-end  rounded-xl drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] mt-[-5px] xl:mt-[-10px] md::mt-[-10px]">
            <button
              onClick={() => {
                item > 1 && setItem((item) => item - 1);
                handleDecrementToCart(
                  // WooloworthsProduct?.parentId ||
                  //   colesProduct?.parentId ||
                  //   amazonProduct?.parentId
                  WooloworthsProduct? {WooloworthsProduct} : colesProduct ? {colesProduct} : amazonProduct && {amazonProduct}
                );
              }}
              class="w-10 h-8 rounded-s-xl bg-gradient-to-b from-[#02C39A] to-[#05668D] text-white focus:outline-none text-xl font-bold flex items-center justify-center"
            >
              <img src={MinusIcon} alt="" />
            </button>
            <span class="text-base text-black h-8 px-4 border-y border-t-[#02C39A] border-b-[#05668D] pt-[2px] ">
              {item}
            </span>
            <button
              onClick={() => {
                setItem((item) => item + 1);
                handleIncrementToCart(
                  woolworthParentProduct ? {woolworthParentProduct} : colesParentProduct ? {colesParentProduct} : amazonParentProduct && {amazonParentProduct}
                );
                // handleIncrementToCart(colesParentProduct, "coles");
              }}
              class="w-10 h-8 rounded-e-xl bg-gradient-to-b from-[#02C39A] to-[#05668D]  text-white focus:outline-none flex items-center justify-center"
            >
              <img src={PlusIcon} alt="" />
            </button>
          </div>

          {isSimilarExist ? (
            <button
              onClick={() => setIsSimilar(!isSimilar)}
              className="bg-[#FFF500] md:py-1 md:px-2 px-1 py-[2px] rounded-lg text-black font-bold md:text-xs text-[14px] flex items-center gap-2"
            >
              <img src={downArrowSvg} alt="" />
              SIMILAR
            </button>
          ) : (
            // <button className="bg-[#e2de8b] text-gray-600 md:py-1 md:px-2 px-1 py-[2px] rounded-lg cursor-text  font-bold md:text-xs text-[14px] flex items-center gap-2">
            //   <img src={downArrowSvg} alt="" />
            //   SIMILAR
            // </button>
            <p className="h-6 w-full"></p>
          )}

          <div
            className={`${
              isSimilar
                ? "translate-x-0 relative"
                : "-translate-x-full absolute top-[-1000px]"
            }   transition-transform duration-300 ease-in-out transform `}
          >
            <SimilarCartCarousel
              WooloworthsProduct={WooloworthsProduct}
              colesProduct={colesProduct}
              amazonProduct={amazonProduct}
              woolsSimilarProduct={woolsSimilarProduct}
              colesSimilarProduct={colesSimilarProduct}
              amazonSimilarProduct={amazonSimilarProduct}
            ></SimilarCartCarousel>
            <div
              onClick={() => setIsSimilar(!isSimilar)}
              className="flex justify-end items-center gap-1 cursor-pointer"
            >
              <p className="underline text-black">Ok</p>
              <img src={upArrowSvg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-[#F5F5F5]" />
    </div>
  );
};

export default ProductCart;

// {
//   /* <div>
//       <div class="flex justify-center items-center">
//         <div class="max-w-md w-[400px] bg-white shadow-lg rounded-3xl overflow-hidden p-5">
//           <div class="md:flex md:flex-row md:justify-between items-center">
//             <div class="md:flex-shrink-0">
//               <img
//                 class="h-12 w-12 lg:h-20 lg:w-20 object-cover"
//                 src={productImg1}
//                 alt=""
//               />
//             </div>
//             <div class="p-3 md:flex-grow">
//               <a
//                 href="#ho"
//                 class="block mt-1 text-sm lg:leading-5 font-bold text-black hover:underline"
//               >
//                 Macro Chicken Thigh Fillets Free Range 380g - 750g
//               </a>
//               <p class="mt-2 text-sm text-black">Macro</p>
//             </div>
//             <div class="flex flex-col justify-between items-end gap-5">
//               <p class="text-black text-xl font-bold">$19.99</p>
//               <div class="flex items-center justify-center rounded-xl drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
//                 <button
//                   onClick={() => item > 1 && setItem((item) => item - 1)}
//                   class="w-10 h-8 rounded-s-xl bg-gradient-to-b from-[#02C39A] to-[#05668D] text-white focus:outline-none text-xl font-bold flex items-center justify-center"
//                 >
//                   <img src={MinusIcon} alt="" />
//                 </button>
//                 <span class="text-base text-black h-8 px-4 border-y border-t-[#02C39A] border-b-[#05668D] pt-[2px]">
//                   {item}
//                 </span>
//                 <button
//                   onClick={() => setItem((item) => item + 1)}
//                   class="w-10 h-8 rounded-e-xl bg-gradient-to-b from-[#02C39A] to-[#05668D]  text-white focus:outline-none flex items-center justify-center"
//                 >
//                   <img src={PlusIcon} alt="" />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div> */
// }
