import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortItem } from "../../features/FilterSearch/FilterSearchSlice";


const SingleRadioSelector = ({options}) => {
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state?.FilterSearchSlice?.sortItem);

  const handleChange = (value) => {
    if (selectedItem === value) {
      dispatch(
        sortItem({sortItem: ''})
      )
    } else {
      dispatch(
        sortItem({sortItem: value})
      )
    }
    
  };

  return (
   <div className="bg-gradient-to-l from-[#02C39A] to-[#05668D] md:w-56 w-60 md:rounded-xl md:p-[1px] m-1 absolute z-50">
     <div className=" p-4  bg-white  md:rounded-xl">
      <div className="radio-buttons flex flex-col mt-4  gap-6">
        {options.map((option) => (
          <label
            key={option.value}
            className="flex items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value={option.value}
              checked={selectedItem.includes(option.value)}
              onChange={() => handleChange(option.value)}
              className="hidden"
            />
            <span
              className={`w-5 h-5 rounded-full ${
                selectedItem.includes(option.value) ? 'bg-gradient-to-r from-[#02C39A] to-[#05668D] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]' : "border border-[#139ed4]"
              }`}
            ></span>
            <span className="ml-2 text-black font-bold md:text-sm text-base">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
   </div>
  );
};

export default SingleRadioSelector;
