import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "swiper/swiper.min.css";
import "./App.css";
// import FMCGLandingPage from "./components/FMCGLandingPage/index";
import LandingPage from "./components/LandingPage/Main/Main";
import Main from "./components/Main/Main/Main";
import Product from "./components/Main/Product/Product";
import Layout from "./components/Utils/Layout";


function App() {
  return (
    <Router>
      {/* <Header></Header> */}
      <Routes>
        <Route path="/" element={<Layout><Main></Main></Layout> }>
          <Route path=":parentItem/:item" element={<Main />}></Route>
        </Route>
        <Route path="product/:id" element={<Layout><Product /></Layout> } />
        {/* <Route path="/test" element={<FMCGLandingPage />} /> */}
        <Route path="/info" element={<LandingPage></LandingPage> } />
        <Route path="*" element={<div className="text-6xl flex flex-col justify-center items-center font-bold h-[60vh]">404 <p className=" text-2xl font-medium">page not found.</p></div>} />
      </Routes>
      {/* <FooterWithSocialMediaIcons></FooterWithSocialMediaIcons> */}
    </Router>
  );
}

export default App;
