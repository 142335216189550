import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import AmazonLogo from "../../../../static/images/InfoPageImages/AmazonLogo.png";
import AsosLogo from "../../../../static/images/InfoPageImages/AsosLogo.png";
import DavidJonesLogo from "../../../../static/images/InfoPageImages/DavidJonesLogo.png";
import EbayLogoRectangle from "../../../../static/images/InfoPageImages/EbayLogoRectangle.png";
import JBHIFILogo from "../../../../static/images/InfoPageImages/JBHIFILogo.png";
import ShowCase1 from "../../../../static/images/InfoPageImages/ShowCase1.png";
import ShowCase2 from "../../../../static/images/InfoPageImages/ShowCase2.png";
import ShowCase3 from "../../../../static/images/InfoPageImages/ShowCase3.png";
import ShowCase4 from "../../../../static/images/InfoPageImages/ShowCase4.png";
import TheIconicLogoRectangle from "../../../../static/images/InfoPageImages/TheIconicLogoRectangle.png";
import LaptopShowCase from "../../../../static/images/InfoPageImages/laptop-showcase.png";
import GetStartedButton from "../GetStartedButton/GetStartedButton";
import "./PriceComparisonShowCase.css";

function PriceComparisonShowCase() {
  const storeImages = [
    EbayLogoRectangle,
    TheIconicLogoRectangle,
    JBHIFILogo,
    AmazonLogo,
    DavidJonesLogo,
    AsosLogo,
  ];
  return (
    <>
      <div className="price-comparison-showcase-container">
        <div className="showcase-container-left">
          <div className="price-comparison-header-left-content-title">
            We don't just save your money on your groceries
          </div>
          <div className="price-comparison-header-left-content-subtitle-container">
            <div className="price-comparison-header-left-content-subtitle">
              We search millions of products to get you the best price on the
              things you want
            </div>
          </div>
          <div className="button-container">
            <GetStartedButton />
          </div>
        </div>
        <div className="showcase-container-right">
          <Carousel
            width="100%"
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            emulateTouch={true}
            autoPlay={true}
            infiniteLoop={true}
            stopOnHover={true}
          >
            <div>
              <img src={ShowCase1} alt="SmartCart" />
            </div>
            <div>
              <img src={ShowCase2} alt="SmartCart" />
            </div>
            <div>
              <img src={ShowCase3} alt="SmartCart" />
            </div>
            <div>
              <img src={ShowCase4} alt="SmartCart" />
            </div>
          </Carousel>
        </div>
      </div>
      <div className="price-comparison-promo-section-participating-store-title">
        ENJOY THE BEST PRICES ACROSS THOUSANDS OF TRUSTED STORES
      </div>
      <div className="price-comparison-promo-section-participating-store">
        {storeImages.map((storeImage, index) => (
          <img
            key={index}
            src={storeImage}
            alt="StoreImage"
            className="price-comparison-promo-section-participating-store-image"
          />
        ))}
      </div>
      <div className="price-comparison-video-container">
        <div className="price-comparison-promo-section-title-2">
          <div className="price-comparison-what-we-do-title">
            Real-time price comparison
          </div>
          <div className="price-comparison-what-we-do-subtitle">
            Save your cash & time by instantly comparing prices for the things
            you want
          </div>
        </div>

        <div className="price-comparison-promo-section-video">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/pznt8UMknOE"
            width="60vw"
            height="50vh"
          />
        </div>
      </div>
      <div className="price-comparison-laptop-image-container">
        <img src={LaptopShowCase} alt="" className="laptop-image" />
      </div>
    </>
  );
}

export default PriceComparisonShowCase;
