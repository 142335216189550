import React from "react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import ProductCart from "./ProductCart";

const SwiperCarousel = ({ allSimilarProducts }) => {
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={1}
      breakpoints={{
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1524: {
          slidesPerView: 4,
        },
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper items-center justify-center pb-6 w-full"
    >
      {allSimilarProducts?.map((product) => {
        return (
          <SwiperSlide>
            <ProductCart product={product}></ProductCart>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default SwiperCarousel;
