import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import amazonLogo from "../../../assets/brands/amazon.png";
import colesLogo from "../../../assets/brands/coles.png";
import wholLogo from "../../../assets/brands/image 23.png";
import MinusIcon from "../../../assets/icons/minusIcon.png";
import PlusIcon from "../../../assets/icons/plusIcon.png";
import { useGetAllProductsQuery } from "../../../features/AllProducts/AllProductsApi";
import { addToCart, decreaseQuantity, updateWoolworthSimilarPro } from "../../../features/Cart/CartSlice";
import { useGetSimilarQuery } from "../../../features/Cart/SimilarProductsApi/SimilarProductsApi";
import { useGetSingleProductQuery } from "../../../features/SingleProduct/SingleProductsApi";
import Blocks from "../../Blocks/Blocks";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import MobileViewSort from "../../MobileSortView/MobileViewSort";
import ProductLoading from "../../Utils/SclatonLoadings/ProductLoading";
import Sidebar from "../Home/Sidebar/Sidebar";
import PriceChartAndDescription from "./PriceChartAndDescription";
import SimilarProducts from "./SimilarProducts";

const Product = () => {
  const goBack = () => {
    window.history.back();
  };

  const dispatch = useDispatch();
  const params = useParams();
  const productId = parseInt(params.id);

  const storedProduct = useSelector(state => state.cart.items?.find(pro => pro.targetProductId === productId));

  const quantity = storedProduct?.woolworth?.quantity || 0;
  const [item, setItem] = useState(quantity);
  const [retryCount, setRetryCount] = useState(0);


  useEffect(() => {
    setItem(quantity)
  },[quantity])




  const isSidebarOn = useSelector(state => state.SidebarStatus.IsSidebarOn);

  const {
    data: getProduct,
    isLoading,
    isError,
  } = useGetSingleProductQuery(productId);

  const product = getProduct?.products[0];

  const taxonomyId = getProduct?.taxonomy_id

  // previous version for similar product which was getting from product substitutes.
  // const colesSimilarProduct = product?.colesProducts?.map(pro => {   return {...pro, store: "coles"} }) || [];
  // const amazonSimilarProduct = product?.amazonProducts?.map(pro => {   return {...pro, store: "amazon"} }) || [];
  // const allSimilarProducts = [...colesSimilarProduct, ...amazonSimilarProduct];

  
  const {
    data: SimilarProData,
    isLoading: similarProloading,
  } = useGetAllProductsQuery({ page: 1, taxonomyId: taxonomyId, limit: 4, excludeProductId: productId});

  const allSimilarProducts = SimilarProData?.products;


  const getMatchStoreProduct = (storeFrom) => {
    const highestMatchScoreObject =
      storeFrom?.length > 0
        ? storeFrom?.reduce((prev, current) => {
            return prev.matchScore > current.matchScore ? prev : current;
          })
        : null;
    return highestMatchScoreObject;
  };
 

  const colesPrice = getMatchStoreProduct(product?.colesProducts)?.currentPrice;
  const amazonPrice = getMatchStoreProduct(
    product?.amazonProducts
  )?.currentPrice;





  

  const ProductFromLocalStorage = useSelector((state) =>
    state.cart.items.find((pro) => productId === pro.targetProductId)
  );

  const productForWoolworthSimilarProduct = getMatchStoreProduct(
    product?.colesProducts
  );

  useEffect(() => {
    setItem(ProductFromLocalStorage?.woolworth?.quantity || 0);
  }, [ProductFromLocalStorage?.woolworth?.quantity]);

  const { data: wools, isSuccess } = useGetSimilarQuery({
    candidateStore: 121,
    productId: productForWoolworthSimilarProduct?.candidateProductId,
  });

  const optionalWoolsPro = {
    cadidateName: product?.targetName,
    candidateProductId: product?.targetProductId,
    candidateProductUrl: product?.targetProductUrl,
    currentPrice: product?.currentPrice,
    matchScore: 100,
    parentId: product?.targetProductId,
    quantity: 1
  };

  const handleIncrementWithRetry = () => {
    if (isSuccess && wools) {
      dispatch(
        updateWoolworthSimilarPro({
          productId,
          woolsProduct: wools.length > 0 ? wools : [optionalWoolsPro],
        })
      );
    } else {
      // Retry after 1 second delay
      setTimeout(() => {
        setRetryCount(retryCount + 1);
        handleIncrementWithRetry();
      }, 1000); // 1000 milliseconds (1 second) delay
    }
  };

  const handleDecrementToCart = (productId) => {
    dispatch(decreaseQuantity({productId}));
  };

  const woolworthParentProduct = useSelector((state) =>
    state.cart.items.find(
      (pro) => pro.targetProductId === product?.targetProductId
    )
  );

  // console.log(woolworthParentProduct);

  const handleIncrementToCart = (FirstProduct) => {
    const product = woolworthParentProduct ? woolworthParentProduct : FirstProduct
    const shop = "wools";
    dispatch(addToCart({ product, shop }));
    if (!woolworthParentProduct) {
      handleIncrementWithRetry();
    }  
  };







  return (
    <>
      <section class="text-gray-600 body-font overflow-hidden relative">
        {/* block buttons for sort, and show or hide product category aside */}

        <MobileViewSort></MobileViewSort>

        <div className="md:relative">
          <Blocks></Blocks>
        </div>
        {isSidebarOn && (
          <div className="md:hidden absolute z-10 top-0 w-screen h-full bg-[#D7D1D1]"></div>
        )}
        {/* <div className={`md:absolute  md:top-20 md:left-0 rounded-xl h-screen`}> */}
        <Sidebar
          className={"absolute top-0"}
        ></Sidebar>
        {/* </div> */}

        <div class="container  pb-24 pt-12 mx-auto">
          <div className="md:ms-[12em] ms-6 text-black">
            <BreadCrumbs
            ></BreadCrumbs>
            <p
              onClick={goBack}
              className="px-10 text-lg underline cursor-pointer font-medium text-black  mb-10"
            >
              Back
            </p>
          </div>
          {isLoading && !isError && <ProductLoading></ProductLoading>}
          {isSuccess && !isError && !isLoading && product ? (
            <div class="lg:w-4/5  flex flex-wrap justify-center">
              <div className="lg:w-2/5 flex items-center justify-center">
                <img
                  alt="ecommerce"
                  class="max-w-full h-auto md:w-[20rem] lg:w-[22rem] object-cover object-center rounded responsive"
                  src={product?.targetProductUrl}
                />
              </div>

              <div class="lg:w-1/2 w-full lg:pl-10 px-4 lg:py-6 mt-6 lg:mt-0">
                {/* <h2 class="text-sm md:text-lg title-font text-[#191919] tracking-widest font-medium mb-6">
                  Brand: <span>Mayver’s</span>
                </h2> */}
                {/* <span class="bg-[#FF0000] text-white text-md font-medium my-3 px-5 py-1 rounded-full drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                  SPECIAL
                </span> */}
                <h1 class="text-gray-900 text-3xl title-font font-medium mb-1 mt-6">
                  {product?.targetName}
                </h1>
                <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 lg:w-[600px]" />

                {/* ----------------product details----------------- */}

                <div class="flex justify-evenly gap-4 sm:-m-4 -mt-4 rounded-2xl lg:w-[600px] ">
                  <div class="p-1 py-6 flex flex-col justify-between items-center">
                    <div class="">
                      <img
                        alt="content"
                        class="object-cover object-center w-16 md:w-20"
                        src={amazonLogo}
                      />
                    </div>
                    <h2 class="text-xl md:text-2xl font-bold title-font text-gray-900 mt-5">
                      {amazonPrice ? `$${amazonPrice}` : "unavailable"}
                    </h2>
                    <p class=" text-[#c9c9c9]  line-through text-xs md:text-lg">
                      $9.00
                    </p>
                    <p class="text-xs md:text-sm  mt-2">$7.00 per 100g</p>
                  </div>
                  <div class="p-1 py-6 flex flex-col justify-between items-center">
                    <div class="">
                      <img
                        alt="content"
                        class="object-cover object-center w-16 md:w-20"
                        src={colesLogo}
                      />
                    </div>
                    <h2 class="text-xl md:text-2xl font-bold title-font text-gray-900 mt-5">
                      {colesPrice ? `$${colesPrice}` : 'unavailable'}
                    </h2>
                    <p class=" text-[#c9c9c9]  line-through text-xs md:text-lg">
                      $9.00
                    </p>
                    <p class="text-xs md:text-sm  mt-2">$7.00 per 100g</p>
                  </div>
                  <div class="p-1 py-6 flex flex-col justify-between items-center">
                    <div class="">
                      <img
                        alt="content"
                        class="object-cover object-center w-16 md:w-20"
                        src={wholLogo}
                      />
                    </div>
                    <h2 class="text-xl md:text-2xl font-bold title-font text-gray-900 mt-5">
                      ${product?.currentPrice}
                    </h2>
                    <p class=" text-[#c9c9c9]  line-through text-xs md:text-lg">
                      $9.00
                    </p>
                    <p class="text-xs md:text-sm  mt-2">$7.00 per 100g</p>
                  </div>
                </div>

                {!item ? (
                  <button
                    onClick={() => {setItem((item) => item + 1);
                      handleIncrementToCart(
                        // storedProduct && {storedProduct}
                        product, "wools"
                        )}}
                    class="relative mt-16 inline-flex items-center justify-center  mb-2 mr-2 overflow-hidden text-lg border border-[#E73C17] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
                  >
                    <span class="relative px-16 py-2.5 transition-all ease-in duration-200 font-medium bg-white dark:bg-gray-900 group-hover:bg-opacity-0  text-[#E73C17] hover:text-white hover:bg-[#E73C17]">
                      Add to Cart
                    </span>
                  </button>
                ) : (
                  <div class="relative mt-16 inline-flex items-center justify-center  mb-2 mr-2 overflow-hidden text-lg drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                    <button
                      onClick={() => {item > 0 && setItem((item) => item - 1);
                        handleDecrementToCart(product?.targetProductId);}}
                      class="w-14 h-[50px] rounded-s-xl bg-gradient-to-b from-[#02C39A] to-[#05668D] text-white focus:outline-none text-xl font-bold flex items-center justify-center"
                    >
                      <img src={MinusIcon} alt="" />
                    </button>
                    <span class="text-lg font-bold text-black h-[50px] px-12 border-y-4 border-t-[#02C39A] border-b-[#05668D] pt-[8px] ">
                      {item}
                    </span>
                    <button
                      onClick={() => {setItem((item) => item + 1);
                        handleIncrementToCart(
                          product
                        );}}
                      class="w-14 h-[50px] rounded-e-xl bg-gradient-to-b from-[#02C39A] to-[#05668D]  text-white focus:outline-none flex items-center justify-center"
                    >
                      <img src={PlusIcon} alt="" />
                    </button>
                  </div>
                )}
                {/* ----------------------------- */}
              </div>
            </div>
          ) : null}

          <PriceChartAndDescription product={product}></PriceChartAndDescription>
        </div>

        {
          similarProloading && <div className="flex justify-center items-center my-4"> <BeatLoader color="#36d7b7" margin={5} size={18} speedMultiplier={1} /></div>
        }
        {
          allSimilarProducts?.length > 0 && !similarProloading && <SimilarProducts allSimilarProducts={allSimilarProducts}></SimilarProducts>
        }
        
      </section>
    </>
  );
};

export default Product;
