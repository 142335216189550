import React from "react";
import SunriseNewsLogo from "../../../../static/images/Sunrise_TV_logo.png";
import Channel7Logo from "../../../../static/images/channel-7-news-logo.jpeg";
import DailyTelegraphLogo from "../../../../static/images/dailytelegraph.svg";
import NewsLogo from "../../../../static/images/news-logo.jpg";
import "./NewsAdvertisement.css";


function NewsAdvertisement() {
  return (
    <>
      <div className="news-section-container">
        <div className="news-section-title">See us in the news</div>
        <div className="news-section-images">
          <img
            src={SunriseNewsLogo}
            alt=""
            className="news-company-image sunrise"
            onClick={() =>
              window.open(
                "https://7news.com.au/sunrise/on-the-show/internet-extension-smartcart-helps-shoppers-save-money-on-their-grocery-bills-c-5258913"
              )
            }
          />
          <img
            src={NewsLogo}
            alt=""
            className="news-company-image news"
            onClick={() =>
              window.open(
                "https://www.news.com.au/finance/business/retail/shopper-develops-site-comparing-cost-of-identical-coles-and-woolworths-groceries/news-story/6d1304185976ecd74565d2339d59c923"
              )
            }
          />
          <img
            src={Channel7Logo}
            alt=""
            className="news-company-image"
            onClick={() =>
              window.open(
                "https://7news.com.au/business/finance/the-apps-helping-you-save-hundreds-on-your-grocery-bill-c-4936367"
              )
            }
          />
          <img
            src={DailyTelegraphLogo}
            alt=""
            className="news-company-image telegraph"
            onClick={() =>
              window.open(
                "https://www.dailytelegraph.com.au/business/internet-tool-reveals-which-supermarket-aussies-prefer-between-coles-and-woolworths/news-story/06d1447ec10e0be5be05b1f972389645"
              )
            }
          />
        </div>
      </div>
    </>
  );
}

export default NewsAdvertisement;