import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  IsSortOn: false,
};

const SortOnOffSlice = createSlice({
  name: "SortOnOff",
  initialState,
  reducers: {
    toggleSortMenu: (state) => {
      state.IsSortOn = !state.IsSortOn;
    },
  },
});

export const { action, toggleSortMenu} = SortOnOffSlice.actions;
export default SortOnOffSlice.reducer;
