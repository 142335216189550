import FooterWithSocialMediaIcons from "../Main/Footer/Footer";
import Header from "../Main/Header/Header";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <FooterWithSocialMediaIcons />
    </div>
  );
};

export default Layout;
