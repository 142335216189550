import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: JSON.parse(localStorage.getItem("cartItems") || "[]"),
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { product, shop } = action.payload;

      

      const existingItem = state.items.find(
        (item) => item.targetProductId === product?.targetProductId
      );

      // Find the object with the highest match score
      const getMatchStoreProduct = (storeFrom) => {
        const highestMatchScoreObject =
          storeFrom?.length > 0
            ? storeFrom?.reduce((prev, current) => {
                return prev.matchScore > current.matchScore ? prev : current;
              })
            : null;
        return highestMatchScoreObject;
      };

      if (existingItem) {
        
        
        if (shop === "coles") {
          existingItem.coles.quantity++;
        } else if (shop === "amazon") {
          existingItem.amazon.quantity++;
        } else if (shop === "wools" && existingItem?.woolworth?.quantity){
          existingItem.woolworth.quantity++;
        } else if (shop === "wools") {
          existingItem.quantity++;
        } 
        
        
      } else {
        const coles = {
          ...getMatchStoreProduct(product?.colesProducts),
          parentId: product?.targetProductId,
          quantity: 1,
        };

        const amazon = {
          ...getMatchStoreProduct(product?.amazonProducts),
          parentId: product?.targetProductId,
          quantity: 1,
        };

        // const similarProductsResponse = useGetSimilarQuery({ productId, candidateStore });

        const updatedColesProducts = product?.colesProducts?.map(
          (pro) => ({
            ...pro,
            parentId: product?.targetProductId,
            quantity: 1,
          })
        );

        const updatedAmazonProducts = product?.amazonProducts?.map(
          (pro) => ({
            ...pro,
            parentId: product?.targetProductId,
            quantity: 1,
          })
        );

        // localStorage.setItem("coles", JSON.stringify({coles,updatedColesProducts, parentId: action.payload.targetProductId}));

        state.items.push({
          ...product,
          quantity: 1,
          coles,
          amazon,
          colesProducts: updatedColesProducts,
          amazonProducts: updatedAmazonProducts,
        });
      }
      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },

    removeFromCart: (state, action) => {
      state.items = state.items.filter(
        (item) => item.targetProductId !== action.payload
      );
      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },


    decreaseQuantity: (state, action) => {

      const {productId, shop} = action.payload;

      const existingItem = state.items.find(
        (item) => item.targetProductId === productId
      );

      if (existingItem) {

        if (shop === "coles") {
          existingItem.coles.quantity--;
        } else if (shop === "amazon") {
          existingItem.amazon.quantity--;
        } else if (shop === "wools") {
          existingItem.quantity--;
        }else if (!shop) {
          existingItem.quantity--;
        }
        else if (existingItem.woolworth) {
          existingItem.woolworth.quantity--;
        }

        if (existingItem?.woolworth?.quantity === 0 || existingItem?.amazon?.quantity === 0 || existingItem?.coles?.quantity === 0 || existingItem?.quantity === 0) {
          state.items = state.items.filter(
            (item) => item.targetProductId !== productId
          );
        }
      }
      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },

    similarProductSelection: (state, action) => {
      const {
        cartItemId,
        selectedColesProduct,
        selectedAmazonProduct,
        selectedWoolProduct,
      } = action.payload;

      const cartItemToUpdate = state.items.find(
        (item) => item.targetProductId === cartItemId
      );

      if (cartItemToUpdate) {
        if (selectedColesProduct) {
          cartItemToUpdate.coles = selectedColesProduct;
        } else if (selectedAmazonProduct) {
          cartItemToUpdate.amazon = selectedAmazonProduct;
        } else if (selectedWoolProduct) {
          cartItemToUpdate.woolworth = selectedWoolProduct;
        }
      }

      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },

    updateWoolworthSimilarPro: (state, action) => {
      // Find the object with the highest match score
      const getMatchStoreProduct = (storeFrom) => {
        const highestMatchScoreObject =
          storeFrom?.length > 0
            ? storeFrom?.reduce((prev, current) => {
                return prev.matchScore > current.matchScore ? prev : current;
              })
            : null;
        return highestMatchScoreObject;
      };

      const woolworth = {
        ...getMatchStoreProduct(action.payload.woolsProduct),
        parentId: action.payload.productId,
        quantity: 1,
      };

      const updatedWoolworthProducts = action.payload.woolsProduct?.map(
        (pro) => ({
          ...pro,
          parentId: action.payload.productId,
          quantity: 1,
        })
      );

      state.items = state.items.map((item) => {
        if (item.targetProductId === action.payload.productId) {
          return {
            ...item,
            woolworth,
            woolWorthProducts:
              updatedWoolworthProducts.length > 1
                ? updatedWoolworthProducts
                : [],
          };
        }
        return item;
      });

      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  decreaseQuantity,
  similarProductSelection,
  updateWoolworthSimilarPro,
} = cartSlice.actions;

export default cartSlice.reducer;
