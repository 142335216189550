import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:5000/api/',
        // baseUrl: 'https://8wl8znojq2.execute-api.ap-southeast-2.amazonaws.com/prod/api/',
        baseUrl: 'https://smartcart-webapi.onrender.com/api/',
        // prepareHeaders: async(headers,{getState, endpoint}) => {
        //     const token = getState()?.auth?.accessToken;
        //     if(token) {
        //         headers.set("Authorization", `Bearer ${token}`);
        //     }
        //     return headers;
        // }
    }),
    tagTypes: [],
    endpoints: (builder) => ({})
})