import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProducts: [],
  taxonomyProducts : []
};

const allProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    addAllProducts: (state, action) => {
        const {data} = action.payload;
        state.allProducts= data
    },
    getTaxonomyProducts: (state, action) => {
        const {taxonomyId} = action.payload;
        const taxonomyProducts = state.allProducts.find((products)=> products.taxonomy_id == taxonomyId)
        state.taxonomyProducts= taxonomyProducts
    }
  },
});

export const {addAllProducts, getTaxonomyProducts} = allProductsSlice.actions;

export default allProductsSlice.reducer;
