import React from "react";
import "react-responsive-modal/styles.css";
import Cart from "./Cart";

const CartModal = ({ open, setOpen, onCloseModal }) => {
  return (
    <div>
      {open && (
        <div className="absolute top-0 left-0 z-40 w-full h-[300%]  bg-[#00000073]"></div>
      )}

      <div
        className={` ${
          open ? " translate-x-0  " : " translate-x-full "
        } top-0 right-0 transition-transform duration-300 ease-out transform  z-50 fixed left-0 w-full h-full overflow-scroll  `}
      >
        {/* bg-[#00000073] */}
        <Cart setOpen={setOpen}></Cart>
      </div>
    </div>
  );
};

export default CartModal;
