import { Card } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import amazonLogo from "../../../assets/brands/amazon.png";
import colesLogo from "../../../assets/brands/coles.svg";
import wholLogo from "../../../assets/brands/image 23.png";
import MinusIcon from "../../../assets/icons/minusIcon.png";
import PlusIcon from "../../../assets/icons/plusIcon.png";
import {
  addToCart,
  decreaseQuantity,
  updateWoolworthSimilarPro,
} from "../../../features/Cart/CartSlice";
import { useGetSimilarQuery } from "../../../features/Cart/SimilarProductsApi/SimilarProductsApi";
// import { useGetSingleProductQuery } from "../../../features/SingleProduct/SingleProductsApi";

const ProductCart = ({ product, simProduct }) => {
  const dispatch = useDispatch();

  const [retryCount, setRetryCount] = useState(0);
  const [item, setItem] = useState(0);
  let productId = product?.targetProductId;

  const ProductFromLocalStorage = useSelector((state) =>
    state.cart.items.find((pro) => productId === pro.targetProductId)
  );

  // Find the object with the highest match score
  const getMatchStoreProduct = (storeFrom) => {
    const highestMatchScoreObject =
      storeFrom?.length > 0
        ? storeFrom?.reduce((prev, current) => {
            return prev.matchScore > current.matchScore ? prev : current;
          })
        : null;
    return highestMatchScoreObject;
  };

  const productForWoolworthSimilarProduct = getMatchStoreProduct(
    product?.colesProducts
  );

  useEffect(() => {
    setItem(ProductFromLocalStorage?.woolworth?.quantity || 0);
  }, [ProductFromLocalStorage?.woolworth?.quantity]);

  const { data: wools, isSuccess } = useGetSimilarQuery({
    candidateStore: 121,
    productId: productForWoolworthSimilarProduct?.candidateProductId,
  });

  const optionalWoolsPro = {
    cadidateName: product.targetName,
    candidateProductId: product.targetProductId,
    candidateProductUrl: product.targetProductUrl,
    currentPrice: product.currentPrice,
    matchScore: 100,
    parentId: product.targetProductId,
    quantity: 1
  };

  const handleIncrementWithRetry = () => {
    if (isSuccess && wools) {
      dispatch(
        updateWoolworthSimilarPro({
          productId,
          woolsProduct: wools.length > 0 ? wools : [optionalWoolsPro],
        })
      );
    } else {
      // Retry after 1 second delay
      setTimeout(() => {
        setRetryCount(retryCount + 1);
        handleIncrementWithRetry();
      }, 1000); // 1000 milliseconds (1 second) delay
    }
  };

  const handleDecrementToCart = (productId) => {
    dispatch(decreaseQuantity({productId}));
  };

  const woolworthParentProduct = useSelector((state) =>
    state.cart.items.find(
      (pro) => pro.targetProductId === product?.targetProductId
    )
  );

  // console.log(woolworthParentProduct);

  const handleIncrementToCart = (FirstProduct) => {
    const product = woolworthParentProduct ? woolworthParentProduct : FirstProduct
    const shop = "wools";
    dispatch(addToCart({ product, shop }));
    if (!woolworthParentProduct) {
      handleIncrementWithRetry();
    }  
  };

  // this was the previous version when the similar product was come from product substitutes.
  // const SimillerProductAmazonStore = getSimilarProductStore?.products[0].amazonProducts
  // const SimillerProductColesStore = getSimilarProductStore?.products[0].colesProducts
  // const SimillerProductAmazonStorePrice = getMatchStoreProduct(SimillerProductAmazonStore)?.currentPrice;
  // const SimillerProductColesStorePrice = getMatchStoreProduct(SimillerProductColesStore)?.currentPrice;

  const woolworthPrice = getMatchStoreProduct(
    product?.woolWorthProducts
  )?.currentPrice;
  const colesPrice = getMatchStoreProduct(product?.colesProducts)?.currentPrice;
  const amazonPrice = getMatchStoreProduct(
    product?.amazonProducts
  )?.currentPrice;

  const colesPricesFromSimilarPro =
    simProduct?.store === "coles" ? simProduct.currentPrice : null;

  const amazonPricesFromSimilarPro =
    simProduct?.store === "amazon" ? simProduct.currentPrice : null;

  // ---------------------

  if (simProduct && !product) {
    productId = simProduct.candidateProductId;
  }

  return (
    <div className="flex justify-center">
      <Card
        imgAlt="Mayver's Smooth Natural Peanut Butter | 375g"
        className=" relative w-[18rem] h-[40rem] flex flex-col items-center justify-center border-none my-5 z-0"
        imgSrc={
          product ? product?.targetProductUrl : simProduct?.candidateProductUrl
        }
      >
        {/* <span class="absolute right-1 top-0 bg-[#FF0000] text-white text-md font-medium my-3 px-5 py-1 rounded-full drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          SPECIAL
        </span> */}

        <Link
          to={`/product/${productId}`}
          className=" font-bold tracking-tight text-gray-900 w-4/5 cursor-pointer"
        >
          {product
            ? product?.targetName.slice(0, 70) + "..."
            : simProduct?.cadidateName.slice(0, 70) + "..."}
        </Link>

        <div className="flex justify-between items-start mb-2">
          <p className="text-sm"></p>

          {item <= 0 ? (
            <button
              onClick={() => {
                setItem((item) => item + 1);
                handleIncrementToCart(product, "wools");
              }}
              type="button"
              class="flex justify-center items-center rounded-full w-8 h-8
            bg-gradient-to-b from-[#02C39A] to-[#05668D] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] "
            >
              <img src={PlusIcon} alt="" />
            </button>
          ) : (
            <div class="flex items-center justify-center  rounded-xl drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
              <button
                onClick={() => {
                  item > 0 && setItem((item) => item - 1);
                  handleDecrementToCart(product?.targetProductId);
                }}
                class="w-10 h-8 rounded-s-xl bg-gradient-to-b from-[#02C39A] to-[#05668D] text-white focus:outline-none text-xl font-bold flex items-center justify-center"
              >
                <img src={MinusIcon} alt="" />
              </button>
              <span class="text-base text-black h-8 px-4 border-y border-t-[#02C39A] border-b-[#05668D] pt-[2px] ">
                {item}
              </span>
              <button
                onClick={() => {
                  setItem((item) => item + 1);
                  handleIncrementToCart(
                    product
                  );
                }}
                class="w-10 h-8 rounded-e-xl bg-gradient-to-b from-[#02C39A] to-[#05668D]  text-white focus:outline-none flex items-center justify-center"
              >
                <img src={PlusIcon} alt="" />
              </button>
            </div>
          )}
        </div>

        {/* -------brads--- */}

        <div class="flex justify-center gap-4 sm:-m-4 -mx-4 -mt-4 bg-[#EFFFFC] rounded-2xl ">
          <div class=" py-3 flex flex-col justify-between items-center">
            <div class="">
              <img
                alt="content"
                class="object-cover object-center w-10"
                src={wholLogo}
              />
            </div>
            {product?.currentPrice && !simProduct?.currentPrice ? (
              <h2 class="text-xl font-bold title-font text-gray-900 mt-5">
                ${product?.currentPrice}
              </h2>
            ) : woolworthPrice ? <h2 class="text-xl font-bold title-font text-gray-900 mt-5">
            ${woolworthPrice}
          </h2> : (
              <h2 class="text-xs title-font text-gray-400 mt-5">unavailable</h2>
              // <h2 class="text-xl font-bold title-font text-gray-900 mt-5">
              //   ${product ? product?.currentPrice : simProduct?.currentPrice}
              // </h2>
            )}
            {/* <h2 class="text-xl font-bold title-font text-gray-900 mt-5">
              ${product ? product?.currentPrice : simProduct?.currentPrice}
            </h2> */}

            {/* <p class=" text-[#c9c9c9]  line-through text-xs">$9.00</p>
            <p class="text-xs  mt-2">$7.00 per 100g</p> */}
          </div>
          <div class="p-1 py-3 flex flex-col justify-between items-center">
            <div class="rounded-lg overflow-hidden">
              <img
                alt="content"
                class="object-cover object-center w-10"
                src={amazonLogo}
              />
            </div>
            {amazonPrice ? (
              <h2 class="text-xl font-bold title-font text-gray-900 mt-5 ">
                ${amazonPrice}
              </h2>
            ) : amazonPricesFromSimilarPro ? (
              <h2 class="text-xl font-bold title-font text-gray-900 mt-5 ">
                ${amazonPricesFromSimilarPro}
              </h2>
            ) : (
              <h2 class="text-xs title-font text-gray-400 mt-5 ">
                unavailable
              </h2>
            )}

            {/* <p class=" mt-2 text-[#c9c9c9] line-through  text-xs">$8.00</p>
            <p class="text-xs mt-2">$7.00 per 100g</p> */}
          </div>
          <div class="p-1 py-3 flex flex-col justify-between items-center">
            <div class="">
              <img
                alt="content"
                class="object-contain object-center w-12 "
                src={colesLogo}
              />
            </div>
            {colesPrice ? (
              <h2 class="text-xl font-bold title-font text-gray-900 mt-5">
                ${colesPrice}
              </h2>
            ) : colesPricesFromSimilarPro ? (
              <h2 class="text-xl font-bold title-font text-gray-900 mt-5">
                ${colesPricesFromSimilarPro}
              </h2>
            ) : (
              <h2 class="text-xs title-font text-gray-400 mt-5 ">
                unavailable
              </h2>
            )}

            {/* <p class=" text-xs mt-2 text-[#c9c9c9] line-through">$10.00</p>
            <p class="text-xs  mt-2">$7.00 per 100g</p> */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ProductCart;
