import React from 'react';
import Typewriter from "typewriter-effect";
import SmartCartComparisonMobile02 from "../../../../static/images/SmartCart_Comparison_Mob02.png";
import GetStartedButton from '../GetStartedButton/GetStartedButton';
import "./Banner.css";

const Banner = () => {
    return (
        <div className="banner">
        <div className="banner-left-typist">
          <div className="banner-left-typist-text">
            <h1>
              Stop paying too much for your
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .pauseFor(100)
                    .typeString("<strong>Milk</strong>")
                    .pauseFor(800)
                    .deleteChars(4)
                    .typeString("<strong>Cereal</strong>")
                    .deleteChars(6)
                    .typeString("<strong>Groceries</strong>")
                    .pauseFor(1000)
                    .start();
                }}
              />
            </h1>
          </div>
          <div className="banner-left-add-desktop">
            <GetStartedButton />
          </div>

          <div className="banner-left-bottom-review">
            <div
              className="advertising-containers"
              onClick={() =>
                window.open(
                  "https://7news.com.au/sunrise/on-the-show/internet-extension-smartcart-helps-shoppers-save-money-on-their-grocery-bills-c-5258913"
                )
              }
            >
              <img
                src="https://www.skymediaproductions.com.au/wp-content/uploads/2015/02/channel-7-news-logo.jpg"
                alt=""
                className="advertising-image"
                style={{ marginRight: "15px" }}
              />
              Check out our interview with Sunrise
            </div>
            <div className="amount-saved">
              SmartCart saves Aussie shoppers an average of{" "}
              <strong>$32.42</strong> per week
            </div>
            <div className="amount-saved-subtitle">
              Compare your cart between Coles & Woolies today
            </div>
          </div>
        </div>
        <div className="banner-right">
          <img
            src={SmartCartComparisonMobile02}
            alt="SmartCart_Comparison_Mob02.png"
            className="banner-right-bot-celebrate-img"
          />
        </div>
      </div>
    );
};

export default Banner;