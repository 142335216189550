import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  IsSidebarOn: false,
};

const SidebarOnOffSlice = createSlice({
  name: "SidebarOnOff",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.IsSidebarOn = !state.IsSidebarOn;
    },
  },
});

export const { action, toggleSidebar} = SidebarOnOffSlice.actions;
export default SidebarOnOffSlice.reducer;
