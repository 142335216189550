import React from 'react';
import Modal from 'react-responsive-modal';

const MobileCartInfoModal = ({open, setOpen, onCloseModal}) => {
    return (
        <div>
            <Modal
        open={open}
        onClose={() => setOpen(false)}
        center={true}
        classNames={{
          modal: `rounded-2xl`,
          overlay: 'fixed top-0 left-0 w-full h-full',
        }}
      >
        <div class="flex justify-start flex-col w-full">
              <h3 className="text-xs w-full px-3 py-4 text-black">
                <span className="font-bold">Your insights:</span><br />
                <br /> <span className="font-bold">Amazon</span> is cheapest by{" "}
                <span className="font-bold">$23.22</span> but is missing{" "}
                <span className="font-bold">4 </span>
                items. <br /> Consider buying those cheaper items from{" "}
                <span className="font-bold">Amazon</span> and the rest from{" "}
                <span className="font-bold">Woolworths</span>.
              </h3>
              <div class="flex items-center gap-2 mt-2">
                <input
                  id="available-items-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-[#C9B8B8] bg-[#C9B8B8] border-[#C9B8B8] rounded focus:ring-[#C9B8B8] focus:ring-0 "
                />
                <label
                  for="available-items-checkbox"
                  class=" text-sm font-medium title-font  text-gray-900"
                >
                  Compare price on available items only
                </label>
              </div>
            </div>
      </Modal>
        </div>
    );
};

export default MobileCartInfoModal;