import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAllProducts } from "../../../features/AllProducts/AllProductsSlice";
import Blocks from "../../Blocks/Blocks";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import MobileViewSort from "../../MobileSortView/MobileViewSort";
import MultipleSelectDropdown from "../../Utils/MultiSelectDropdown";
import SortByDropdown from "../../Utils/SortByDropdown";
import Products from "../Home/Products/Products";
import Sidebar from "../Home/Sidebar/Sidebar";

const Main = () => {
  const dispatch = useDispatch();
  const [sortDropdown, setSortDropdown] = useState(false);
  const [brandDropdown, setBrandDropdown] = useState(false);

  const isSidebarOn = useSelector(state => state.SidebarStatus.IsSidebarOn);


  useEffect(() => {
    fetch("Products.json")
      .then((response) => response.json())
      .then((data) => {
        dispatch(addAllProducts({data}))
      }, 
      // setIsLoading(false)
      );   
  }, [dispatch]);

  const closeSortDropdown = () => {
    setSortDropdown(false);
  };

  const closeBrandDropdown = () => {
    setBrandDropdown(false);
  };

  const sortOptions = [
    // { label: "Specials", value: "Specials" },
    { label: "Price Low to High", value: "ascPrice" },
    { label: "Price High to Low", value: "descPrice" },
    // { label: "New", value: "New" },
    { label: "A to Z (Product name)", value: "ascName" },
    { label: "Z to A (Product name)", value: "descName" },
  ];

  const BrandOptions = [
    { label: "Mayver's", value: "Mayver's" },
    { label: "Attaya", value: "Attaya" },
    { label: "Rowly", value: "Rowly" },
    { label: "Pran", value: "Pran" },
    { label: "Nido", value: "Nido" },
    { label: "Pusti", value: "Pusti" },
  ];

  return (
    <div className="overflow-x-hidden max-w-7xl mx-auto min-h-[60vh]">
      {/* block buttons for sort, and show or hide product category aside */}
      <Blocks></Blocks>

      <MobileViewSort></MobileViewSort>

      {/* home section */}
      <section
        className={`${
          isSidebarOn ? "absolute md:relative top-0" : "relative"
        } flex justify-start  max-w-screen-2xl mx-auto`}
      >
        {/* aside */}
        {isSidebarOn && (
          <div className="md:hidden absolute z-10 top-0 w-screen h-screen bg-[#D7D1D1]"></div>
        )}
        <Sidebar
          className={"md:relative absolute"}
          // isOpen={isOpen}
          // setIsOpen={setIsOpen}
        ></Sidebar>

        {/* home page all products */}
        <div className="md:w-full z-0">
          <BreadCrumbs></BreadCrumbs>

          {/* ---sort and brand dropdown----- */}
          <div className="hidden md:flex items-center gap-10 ml-10 mt-7 mb-4">
            <div>
              <button
                onClick={() => {
                  setSortDropdown(!sortDropdown);
                  setBrandDropdown(false);
                }}
                class=""                
              >
                <span class="text-black border  rounded-xl text-sm px-5 py-2.5 text-center mr-2 border-[#02C39A] md:flex gap-2 hidden font-bold  items-center justify-center"
                >
                  <p className="">Sort by</p>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.98999 15.49L9.00999 20.5V3.5M20.01 8.51L14.99 3.5V20.5"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
              {sortDropdown ? (
                <>
                  <SortByDropdown options={sortOptions}></SortByDropdown>
                  <div
                    className="fixed inset-0 z-30"
                    onClick={closeSortDropdown}
                  ></div>
                </>
              ) : null}
            </div>
            {/* ---- */}
            <div>
              {/* brand button */}
              {/* <button
                onClick={() => {
                  setBrandDropdown(!brandDropdown);
                  setSortDropdown(false);
                }}
                className=''
              >
                <span class="text-black border  rounded-xl text-sm px-5 py-2.5 text-center mr-2 border-[#02C39A] md:flex gap-2 hidden font-bold  items-center justify-center">
                  <p className="">Brands</p>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.98999 15.49L9.00999 20.5V3.5M20.01 8.51L14.99 3.5V20.5"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button> */}
              {brandDropdown ? (
                <>
                  <MultipleSelectDropdown
                    options={BrandOptions}
                  ></MultipleSelectDropdown>
                  <div
                    className="fixed inset-0 opacity-0 z-30"
                    onClick={closeBrandDropdown}
                  ></div>
                </>
              ) : null}
            </div>
          </div>

          <Products></Products>
        </div>
      </section>
    </div>
  );
};

export default Main;
