import React from "react";
import FacebookIcon from "../../../../static/images/facebook-black-icon.svg";
import InstagramIcon from "../../../../static/images/instagram-black-icon.svg";
import LinkedInIcon from "../../../../static/images/linkedIn-black-icon.svg";
import SmartifyBot from "../../../../static/images/smartify-bot-default.svg";
import YoutubeIcon from "../../../../static/images/youtube-black-icon.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-block-about-us-container">
        <div className="footer-block-about-us">
          <div className="footer-block-about-us-header">What is SmartCart?</div>
          <div>
            SmartCart compares your entire cart between Coles and Woolies so you
            can always get the best possible price for your weekly groceries
            shop. Just shop as normal and look out for the compare price button
            to instantly have your cart analysed and compared for the best
            price. If it's cheaper somewhere else we can also rebuild your cart
            so you don't have to worry about finding all of your products again.
            SmartCart also automatically applies promotional codes at the
            checkout so you always get the best price for the things you want.
            SmartCart will find and apply Woolies discount codes. And can also
            apply Coles discount codes. Not only does SmartCart Australia
            compare your cart between Coles and Woolworths and apply the hottest
            promo and coupon codes we also do product price comparison across
            millions of products so you always get the best price for the things
            you want. We also let you know when the brands that you love go on
            sale or have discounts. SmartCart works on all major brands
            including, The Iconic, Asos, eBay Australia, Amazon Australia and
            top brands such as Adidas, Nike, P.E Nation, Bose, Sony, Xbox, Apple
            and many more.
          </div>
        </div>
      </div>
      <div className="footer-middle-block">
        <div className="footer-middle-block-left-side">
          <div>
            <div className="footer-middle-block-name-container">
              <img
                src={SmartifyBot}
                alt=""
                className="footer-middle-block-logo"
              />
              {/* <div
                    className="footer-middle-block-name"
                    onClick={() => history.push("/")}
                  >
                    SmartCart
                  </div> */}
            </div>
            <div className="footer-middle-block-copyright">
              Copyright © 2022 Smartify.ai
            </div>
            <div className="footer-middle-block-feedback">
              Questions & Feedback - please reach out to <br />
              <a href="mailto:admin@smartify.ai"> admin@smartify.ai</a>
            </div>

            <div className="footer-middle-block-social-block">
              <img
                className="footer-middle-block-social-link"
                src={FacebookIcon}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100073096563666",
                    "_blank"
                  )
                }
                alt=""
              />
              <img
                className="footer-middle-block-social-link"
                src={LinkedInIcon}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/smartifyai/",
                    "_blank"
                  )
                }
                alt=""
              />
              <img
                className="footer-middle-block-social-link"
                src={InstagramIcon}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/joinsmartcart/",
                    "_blank"
                  )
                }
                alt=""
              />
              <img
                className="footer-middle-block-social-link"
                src={YoutubeIcon}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCXpcuQfER9NozuuL6Gh9k1w",
                    "_blank"
                  )
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
