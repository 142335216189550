import React, { useState } from "react";
import { useSelector } from "react-redux";
import amazonImg from "../../assets/icons/amazonLogo.svg";
import colesImg from "../../assets/icons/clsLogo.svg";
import wolworthImg from "../../assets/icons/wolsLogo.svg";
import BrandCart from "../Utils/BrandCart";
import MobileViewCart from "./MobleViewCart";
import ProductCart from "./ProductCart";

const Cart = ({ setOpen }) => {
  const [openInsight, setOpenInsight] = useState(false);

  const ParentProductCart = useSelector((state) => state.cart.items);

  let WooloworthsProductCart = ParentProductCart.map((pro) => pro.woolworth) ;

  const colesProductCart = ParentProductCart.map((pro) => pro?.coles);

  const amazonProductCart = ParentProductCart.map((pro) => pro?.amazon);


  //total price calculation.
  const reducer = (total, product) =>
    total + (product?.currentPrice ? Number(product.currentPrice) : 0) * Number(product?.quantity);

  const WooloworthsTotalPrice = WooloworthsProductCart[0]? WooloworthsProductCart.reduce(
    reducer,
    0
  )?.toFixed(2) : ((ParentProductCart[0]?.currentPrice) * (ParentProductCart[0]?.quantity) || 0)?.toFixed(2);

  const colesTotalPrice = colesProductCart.reduce(
    reducer,
    0
  ).toFixed(2);

  const amazonTotalPrice = amazonProductCart.reduce(
    reducer,
    0
  ).toFixed(2);

  return (
    <>
      <section class="text-gray-600 body-font bg-[#F5F5F5] md:w-[85%] md:ms-[15%] p-0 md:p-4 xl:p-5 ">
        <div class="container  md:py-4 py-16 md:bg-none mx-auto ">
          <button
            className=" px-5 py-3 rounded-3xl fixed top-2 md:top-3 right-2 md:right-10 flex justify-center items-center bg-white font-bold gap-2"
            onClick={() => setOpen(false)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="black"
              />
            </svg>
            Close
          </button>

          {/* {!openInsight ? (
            <div className="hidden md:flex flex-col justify-start mb-3">
              <div
                onClick={() => setOpenInsight(!openInsight)}
                className="flex justify-start items-center gap-2"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99933 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99933 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                    fill="#D9D9D9"
                  />
                </svg>
                <p>Explore insights </p>
              </div>
              <div class="flex items-center gap-2 mt-2">
                <input
                  id="available-items-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-[#C9B8B8] bg-[#C9B8B8] border-[#C9B8B8] rounded focus:ring-[#C9B8B8] focus:ring-0 "
                />
                <label
                  for="available-items-checkbox"
                  class=" text-sm font-medium title-font  text-gray-900"
                >
                  Compare price on available items only
                </label>
              </div>
            </div>
          ) : null}

          {openInsight ? (
            <>
              <div className="hidden md:flex  items-center ml-5 mb-4">
                <img className="w-14" src={logo} alt="" />
                <div className="flex gap-2 relative mr-10">
                  <div className=" absolute bottom-5 left-0  py-[3.5px]  px-[5px] rounded-full bg-[#EBE8E8]"></div>
                  <div className=" absolute bottom-7 left-2  py-[5.5px] px-[7px] rounded-full bg-[#EBE8E8]"></div>
                  <div className="absolute bottom-5 left-5 mb-4  py-[7.5px] px-[9px] rounded-full bg-[#EBE8E8]"></div>
                </div>
                <div class="hidden md:flex justify-start flex-col w-full mb-3 relative">
                  <h3 className="text-sm w-3/5 bg-[#EBE8E8] rounded-full ps-4 pe-1 py-4 text-black">
                    <span className="font-bold">Your insights:</span>
                    <br /> <span className="font-bold">Amazon</span> is cheapest
                    by <span className="font-bold">$23.22</span> but is missing{" "}
                    <span className="font-bold">4 </span>
                    items. <br /> Consider buying those cheaper items from{" "}
                    <span className="font-bold">Amazon</span> and the rest from{" "}
                    <span className="font-bold">Woolworths</span>.
                  </h3>

                  <div
                    onClick={() => setOpenInsight(!openInsight)}
                    className="absolute top-0 right-[40%] cursor-pointer"
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_347_2937)">
                        <circle cx="6.5" cy="6.5" r="6.5" fill="#8E8686" />
                        <path
                          d="M3.46665 10.2918L2.70831 9.5335L5.74165 6.50016L2.70831 3.46683L3.46665 2.7085L6.49998 5.74183L9.53331 2.7085L10.2916 3.46683L7.25831 6.50016L10.2916 9.5335L9.53331 10.2918L6.49998 7.2585L3.46665 10.2918Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_347_2937">
                          <rect width="13" height="13" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div class="flex items-center gap-2 mt-2">
                    <input
                      id="available-items-checkbox"
                      type="checkbox"
                      value=""
                      class="w-4 h-4 text-[#C9B8B8] bg-[#C9B8B8] border-[#C9B8B8] rounded focus:ring-[#C9B8B8] focus:ring-0 "
                    />
                    <label
                      for="available-items-checkbox"
                      class=" text-sm font-medium title-font  text-gray-900"
                    >
                      Compare price on available items only
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null} */}

          <div class="container w-full hidden md:grid md:grid-cols-3 mx-auto -m-2 md:mt-6 mb-8 gap-2">
            <div>
              <BrandCart
                brandImg={wolworthImg}
                WooloworthsTotalPrice={WooloworthsTotalPrice}
                
              ></BrandCart>
              <div className="rounded-3xl bg-white">
                {WooloworthsProductCart[0] ? WooloworthsProductCart.map((WooloworthsProduct) => {
                  return (
                    <ProductCart
                    ParentProductCart={ParentProductCart}
                      WooloworthsProduct={WooloworthsProduct}
                      key={WooloworthsProduct?.parentId}
                    ></ProductCart>
                  );
                }) 
                : 
                ParentProductCart?.map((ParentProduct) => {
                  return (
                    <ProductCart
                    ParentProduct={ParentProduct}
                      // WooloworthsProduct={WooloworthsProduct}
                      key={ParentProduct?.targetProductId}
                    ></ProductCart>
                  );
                })
                }
              </div>
            </div>
            <div>
              <BrandCart brandImg={colesImg} colesTotalPrice={colesTotalPrice}></BrandCart>
              <div className="rounded-3xl bg-white">
                {colesProductCart?.map((colesProduct) => {
                  return (
                    <ProductCart
                      colesProduct={colesProduct}
                      key={colesProduct?.parentId}
                    ></ProductCart>
                  );
                })}
              </div>
            </div>
            <div>
              <BrandCart brandImg={amazonImg} amazonTotalPrice={amazonTotalPrice}></BrandCart>
              <div className="rounded-3xl bg-white">
                {amazonProductCart?.map((amazonProduct) => {
                  return (
                    <ProductCart
                      amazonProduct={amazonProduct}
                      key={amazonProduct?.parentId}
                    ></ProductCart>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="md:hidden block">
            <MobileViewCart></MobileViewCart>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
