import React from "react";
import SwiperCarousel from "./SwiperCarousel";

const SimilarProducts = ({allSimilarProducts}) => {
  return (
    <div>
      <div class=" body-font">
        <h4 className="container ms-10 text-3xl font-medium">
          Similar products
        </h4>
        <hr class="container  my-4 bg-gray-50 md:my-4" />
        <div class="container px-5 py-24 mx-auto">
          <div class="flex w-full mx-auto md:flex-nowrap flex-wrap justify-center items-center ">
            <SwiperCarousel allSimilarProducts={allSimilarProducts}></SwiperCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimilarProducts;
