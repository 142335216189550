import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EffectCoverflow } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import amazonImg from "../../assets/brands/amazon.png";
import coalsImg from "../../assets/brands/coles.svg";
import wolhImg from "../../assets/brands/image 23.png";
import BrandCart from "../Utils/BrandCart";
import ProductCart from "./ProductCart";

const MobileViewCart = () => {
  const swiperRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const ParentProductCart = useSelector((state) => state.cart.items);

  let WooloworthsProductCart = ParentProductCart.map((pro) => pro?.woolworth);

  const colesProductCart = ParentProductCart.map((pro) => pro?.coles);

  const amazonProductCart = ParentProductCart.map((pro) => pro?.amazon);

  //total price calculation.
  const reducer = (total, product) =>
    total + (product?.currentPrice ? Number(product.currentPrice) : 0) * Number(product?.quantity);

  const WooloworthsTotalPrice = WooloworthsProductCart.reduce(
    reducer,
    0
  ).toFixed(2);

  const colesTotalPrice = colesProductCart.reduce(
    reducer,
    0
  ).toFixed(2);

  const amazonTotalPrice = amazonProductCart.reduce(
    reducer,
    0
  ).toFixed(2)


  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <div className="flex justify-center items-center gap-1 my-4 px-2 ">
        <div
          className={`rounded-2xl p-3 ${
            activeSlide === 0 ? "bg-[#FFF8E7]" : "bg-white"
          }  drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] w-full mx-auto flex flex-col justify-center items-center`}
        >
          <img className="w-12" src={wolhImg} alt="" />
          <p className="text-2xl font-bold text-black">
            ${WooloworthsTotalPrice}
          </p>
        </div>
        <div
          className={`rounded-2xl p-3 ${
            activeSlide === 1 ? "bg-[#FFF8E7]" : "bg-white"
          }  drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] w-full mx-auto flex flex-col justify-center items-center`}
        >
          <img className="w-[74px] " src={coalsImg} alt="" />

          <p className="text-2xl font-bold text-black">${colesTotalPrice}</p>
        </div>
        <div
          className={`rounded-2xl p-3 ${
            activeSlide === 2 ? "bg-[#FFF8E7]" : "bg-white"
          }  drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] w-full mx-auto flex flex-col justify-center items-center`}
        >
          <img className="w-12" src={amazonImg} alt="" />
          <p className="text-2xl font-bold text-black">${amazonTotalPrice}</p>
        </div>
      </div>

      <Swiper
        ref={swiperRef}
        spaceBetween={2}
        effect={"coverflow"}
        grabCursor={true}
        allowTouchMove={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow]}
        className="mySwiper flex items-center justify-center pb-7 w-full mx-auto"
        onSlideChange={(swiper) =>
          setActiveSlide((prevIndex) => swiper.activeIndex)
        }
      >
        <SwiperSlide>
          <div className="">
            <div className="flex gap-2 items-center">
              <button
                className="bg-[#D9D9D9] py-16 rounded-e-3xl px-1"
                onClick={handlePrevSlide}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.85473 13.111C6.66768 9.28643 10.3944 5.55306 14.0349 1.91419C14.2255 1.71387 14.3318 1.44797 14.3318 1.17149C14.3318 0.895016 14.2255 0.629116 14.0349 0.428798C13.5194 -0.144802 12.7037 -0.0950676 12.3324 0.296174C8.55923 4.07928 4.612 8.03313 0.490709 12.1594C0.164122 12.428 0 12.7463 0 13.111C0 13.4774 0.164122 13.8039 0.490709 14.0924L12.896 26.2043C13.134 26.4232 13.448 26.5406 13.7712 26.5317C14.0944 26.5227 14.4013 26.388 14.6268 26.1562C15.2203 25.5611 14.9915 24.9742 14.7229 24.6957C10.7606 20.8399 6.80568 16.9784 2.85473 13.111Z"
                    fill="white"
                  />
                </svg>
              </button>
              <BrandCart
                brandImg={wolhImg}
                WooloworthsTotalPrice={WooloworthsTotalPrice}
              ></BrandCart>
              <button
                className="bg-[#D9D9D9] py-16 rounded-s-3xl px-1"
                onClick={handleNextSlide}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1453 13.4212C8.33232 17.2458 4.60559 20.9792 0.965054 24.618C0.774497 24.8184 0.668226 25.0843 0.668226 25.3607C0.668226 25.6372 0.774497 25.9031 0.965054 26.1034C1.48063 26.677 2.29627 26.6273 2.66761 26.2361C6.44077 22.4529 10.388 18.4991 14.5093 14.3728C14.8359 14.1043 15 13.786 15 13.4212C15 13.0549 14.8359 12.7283 14.5093 12.4398L2.10396 0.327931C1.866 0.109027 1.55204 -0.00842021 1.22883 0.000557649C0.905621 0.00953551 0.598662 0.144229 0.37322 0.376006C-0.220274 0.971156 0.00850419 1.55802 0.277067 1.83653C4.23935 5.69229 8.19432 9.55387 12.1453 13.4212Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="flex gap-2 mt-3">
              <div className="bg-[#D9D9D9] rounded-e-3xl w-6 "></div>
              <div className="rounded-3xl bg-white">
                {WooloworthsProductCart?.map((WooloworthsProduct) => {
                  return (
                    <ProductCart
                    WooloworthsProduct={WooloworthsProduct}
                      key={WooloworthsProduct?.candidateProductId}
                    ></ProductCart>
                  );
                })}
                {/* <ProductCart></ProductCart>
                <ProductCart></ProductCart>
                <ProductCart></ProductCart> */}
              </div>
              <div className="bg-[#D9D9D9]  rounded-s-3xl w-6 "></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="flex gap-2 items-center">
              <button
                className="bg-[#D9D9D9] py-16 rounded-e-3xl px-1"
                onClick={handlePrevSlide}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.85473 13.111C6.66768 9.28643 10.3944 5.55306 14.0349 1.91419C14.2255 1.71387 14.3318 1.44797 14.3318 1.17149C14.3318 0.895016 14.2255 0.629116 14.0349 0.428798C13.5194 -0.144802 12.7037 -0.0950676 12.3324 0.296174C8.55923 4.07928 4.612 8.03313 0.490709 12.1594C0.164122 12.428 0 12.7463 0 13.111C0 13.4774 0.164122 13.8039 0.490709 14.0924L12.896 26.2043C13.134 26.4232 13.448 26.5406 13.7712 26.5317C14.0944 26.5227 14.4013 26.388 14.6268 26.1562C15.2203 25.5611 14.9915 24.9742 14.7229 24.6957C10.7606 20.8399 6.80568 16.9784 2.85473 13.111Z"
                    fill="white"
                  />
                </svg>
              </button>
              <BrandCart brandImg={coalsImg} colesTotalPrice={colesTotalPrice}></BrandCart>
              <button
                className="bg-[#D9D9D9] py-16 rounded-s-3xl px-1"
                onClick={handleNextSlide}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1453 13.4212C8.33232 17.2458 4.60559 20.9792 0.965054 24.618C0.774497 24.8184 0.668226 25.0843 0.668226 25.3607C0.668226 25.6372 0.774497 25.9031 0.965054 26.1034C1.48063 26.677 2.29627 26.6273 2.66761 26.2361C6.44077 22.4529 10.388 18.4991 14.5093 14.3728C14.8359 14.1043 15 13.786 15 13.4212C15 13.0549 14.8359 12.7283 14.5093 12.4398L2.10396 0.327931C1.866 0.109027 1.55204 -0.00842021 1.22883 0.000557649C0.905621 0.00953551 0.598662 0.144229 0.37322 0.376006C-0.220274 0.971156 0.00850419 1.55802 0.277067 1.83653C4.23935 5.69229 8.19432 9.55387 12.1453 13.4212Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="flex gap-2 mt-3">
              <div className="bg-[#D9D9D9] rounded-e-3xl w-6 "></div>
              <div className="rounded-3xl bg-white">
                {colesProductCart?.map((colesProduct) => {
                  return (
                    <ProductCart
                      colesProduct={colesProduct}
                      key={colesProduct?.candidateProductId}
                    ></ProductCart>
                  );
                })}
              </div>
              <div className="bg-[#D9D9D9]  rounded-s-3xl w-6 "></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="flex gap-2 items-center">
              <button
                className="bg-[#D9D9D9] py-16 rounded-e-3xl px-1"
                onClick={handlePrevSlide}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.85473 13.111C6.66768 9.28643 10.3944 5.55306 14.0349 1.91419C14.2255 1.71387 14.3318 1.44797 14.3318 1.17149C14.3318 0.895016 14.2255 0.629116 14.0349 0.428798C13.5194 -0.144802 12.7037 -0.0950676 12.3324 0.296174C8.55923 4.07928 4.612 8.03313 0.490709 12.1594C0.164122 12.428 0 12.7463 0 13.111C0 13.4774 0.164122 13.8039 0.490709 14.0924L12.896 26.2043C13.134 26.4232 13.448 26.5406 13.7712 26.5317C14.0944 26.5227 14.4013 26.388 14.6268 26.1562C15.2203 25.5611 14.9915 24.9742 14.7229 24.6957C10.7606 20.8399 6.80568 16.9784 2.85473 13.111Z"
                    fill="white"
                  />
                </svg>
              </button>
              <BrandCart brandImg={amazonImg} amazonTotalPrice={amazonTotalPrice}></BrandCart>
              <button
                className="bg-[#D9D9D9] py-16 rounded-s-3xl px-1"
                onClick={handleNextSlide}
              >
                <svg
                  width="15"
                  height="27"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1453 13.4212C8.33232 17.2458 4.60559 20.9792 0.965054 24.618C0.774497 24.8184 0.668226 25.0843 0.668226 25.3607C0.668226 25.6372 0.774497 25.9031 0.965054 26.1034C1.48063 26.677 2.29627 26.6273 2.66761 26.2361C6.44077 22.4529 10.388 18.4991 14.5093 14.3728C14.8359 14.1043 15 13.786 15 13.4212C15 13.0549 14.8359 12.7283 14.5093 12.4398L2.10396 0.327931C1.866 0.109027 1.55204 -0.00842021 1.22883 0.000557649C0.905621 0.00953551 0.598662 0.144229 0.37322 0.376006C-0.220274 0.971156 0.00850419 1.55802 0.277067 1.83653C4.23935 5.69229 8.19432 9.55387 12.1453 13.4212Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>

            <div className="flex gap-2 mt-3">
              <div className="bg-[#D9D9D9] rounded-e-3xl w-6 "></div>
              <div className="rounded-3xl bg-white">
                {amazonProductCart?.map((amazonProduct) => {
                  return (
                    <ProductCart
                      amazonProduct={amazonProduct}
                      key={amazonProduct?.candidateProductId}
                    ></ProductCart>
                  );
                })}
              </div>
              <div className="bg-[#D9D9D9]  rounded-s-3xl w-6 "></div>
            </div>
          </div>
        </SwiperSlide>

        {/* Add more SwiperSlides for additional cards */}
      </Swiper>
    </>
  );
};

export default MobileViewCart;
