import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EffectCoverflow, Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { toggleSortMenu } from "../../features/Utils/sortOnOffSlice";
import BrandSelector from "../Utils/MultiSelectDropdown";
import SortByDropdown from "../Utils/SortByDropdown";

const MobileViewSort = () => {
  const isSortOn = useSelector((state) => state.SortStatus.IsSortOn);
  const dispatch = useDispatch();
  const [activeSlide, setActiveSlide] = useState(0);

  const swiperRef = useRef(null);

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

//   useEffect(() =>{
//     setActiveSlide(swiperRef?.current?.swiper?.activeIndex   + 1)
//   },[swiperRef.current?.swiper?.activeIndex ])

//   const activePage = swiperRef.current.swiper.realIndex  + 1

const sortOptions = [
  // { label: "Specials", value: "Specials" },
  { label: "Price Low to High", value: "ascPrice" },
  { label: "Price High to Low", value: "descPrice" },
  // { label: "New", value: "New" },
  { label: "A to Z (Product name)", value: "ascName" },
  { label: "Z to A (Product name)", value: "descName" },
];

  const BrandOptions = [
    { label: "Mayver's", value: "Mayver's" },
    { label: "Attaya", value: "Attaya" },
    { label: "Rowly", value: "Rowly" },
    { label: "Pran", value: "Pran" },
    { label: "Nido", value: "Nido" },
    { label: "Pusti", value: "Pusti" },
  ];

  return (
    <div>
      {/* <!-- drawer component --> */}
      <div>
        <div
          class={`${
            isSortOn
              ? `translate-y-0 duration-150 ease-out transform transition-transform`
              : " duration-150 ease-out transition-transform transform translate-y-full"
          } fixed bottom-0 md:hidden left-0 right-0  z-40 w-full h-[40%] p-4 ease-in-out transform overflow-y-auto bg-white  rounded-t-2xl border-b-0 border-2 border-[#11d6ab]`}
        >

          <div className="flex justify-center items-center gap-5 mt-1">
            <button onClick={handlePrevSlide}>
              <svg
                width="12"
                height="25"
                viewBox="0 0 12 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1.00015L1.81367 10.1865C0.728779 11.2714 0.728778 13.0466 1.81367 14.1315L11 23.3179"
                  stroke="#DAC4C4"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className="text-[#D39797] text-[16px]">{activeSlide + 1}</p>
            <button onClick={handleNextSlide}>
              <svg
                width="12"
                height="25"
                viewBox="0 0 12 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 23.3177L10.1863 14.1314C11.2712 13.0465 11.2712 11.2712 10.1863 10.1863L1 1"
                  stroke="#DAC4C4"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <button
            onClick={() => dispatch(toggleSortMenu())}
            type="button"
            data-drawer-hide="drawer-bottom-example"
            aria-controls="drawer-bottom-example"
            class="text-gray-400 bg-transparent rounded-lg text-sm w-9 h-9 absolute top-2.5 right-2.5 inline-flex items-center justify-center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_382_1695)">
                <circle cx="10" cy="10" r="10" fill="#8E8686" />
                <path
                  d="M5.33317 15.8332L4.1665 14.6665L8.83317 9.99984L4.1665 5.33317L5.33317 4.1665L9.99984 8.83317L14.6665 4.1665L15.8332 5.33317L11.1665 9.99984L15.8332 14.6665L14.6665 15.8332L9.99984 11.1665L5.33317 15.8332Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_382_1695">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span class="sr-only">Close menu</span>
          </button>

          <Swiper
            ref={swiperRef}
            spaceBetween={2}
            // effect={"coverflow"}
            grabCursor={true}
            allowTouchMove={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            // coverflowEffect={{
            //   rotate: 50,
            //   stretch: 0,
            //   depth: 100,
            //   modifier: 1,
            //   slideShadows: true,
            // }}
            // pagination={{
            //   clickable: true,
            // }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper  h-full w-full mx-auto pb-2"
            onSlideChange= { (swiper) => setActiveSlide ( (prevIndex) => swiper.activeIndex )}

          >
            <SwiperSlide>
              <div className="h-full">
                <div>
                  <h4 className="font-bold ">Sort by</h4>
                  <hr class="h-px mt-1 -mb-1 bg-gray-200 border-0"></hr>
                  <SortByDropdown options={sortOptions}></SortByDropdown>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-full">
                <div>
                  <h4 className="font-bold ">Brand by</h4>
                  <hr class="h-px mt-1 -mb-1 bg-gray-200 border-0"></hr>
                  <BrandSelector options={BrandOptions}></BrandSelector>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {isSortOn ? (
          <div className="fixed inset-0 opacity-50 z-30 bg-black"></div>
        ) : null}
      </div>
    </div>
  );
};

export default MobileViewSort;
