import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const PriceChart = ({pricingHistory}) => {
  // const dummyData = [
  //   {
  //     // name: "Nov",
  //     woolworths: 1.82,
  //     amazon: 1.12,
  //     coles: 1.30,
  //   },
  //   {
  //     // name: "Jan",
  //     woolworths: 2.85,
  //     amazon: 2.29,
  //     coles: 2.50,
  //   },
  //   {
  //     // name: "Feb",
  //     woolworths: 1.85,
  //     amazon: 1.11,
  //     coles: 1.43,
  //   },
  //   {
  //     // name: "Mar",
  //     woolworths: 2.23,
  //     amazon: 2.91,
  //     coles: 2.50,
  //   },
  //   {
  //     // name: "April",
  //     woolworths: 1.01,
  //     amazon: 1.28,
  //     coles: 1.91,
  //   },
  // ];


  return (
    <>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          data={pricingHistory}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          {/* <XAxis dataKey="name" /> */}
          {/* <YAxis /> */}
          <Tooltip />
          <defs>
            <linearGradient id="lineGradient1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(43, 63, 242, 0.15)" />
              <stop offset="100%" stopColor="rgba(43, 63, 242, 0)" />
            </linearGradient>
            <linearGradient id="lineGradient2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(142, 242, 126, 0.15)" />
              <stop offset="100%" stopColor="rgba(142, 242, 126, 0)" />
            </linearGradient>
            <linearGradient id="lineGradient3" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(255, 216, 79, 0.15)" />
              <stop offset="100%" stopColor="rgba(255, 216, 79, 0)" />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="amazon"
            stroke="#2B3FF2"
            fill="url(#lineGradient1)"
            strokeWidth={2}
          />
          <Area
            type="monotone"
            dataKey="woolworths"
            stroke="#8EE27E"
            fill="url(#lineGradient2)"
            strokeWidth={2}
          />
          <Area
            type="monotone"
            dataKey="coles"
            stroke="#FFD84F"
            fill="url(#lineGradient3)"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default PriceChart;
