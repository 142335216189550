import React, { useEffect, useState } from 'react';
import SmartCart_Comparison_Mob02 from "../../../../static/images/SmartCart_Comparison_Mob02.png";
import SmartCart_Comparison_WW_Text from "../../../../static/images/SmartCart_Comparison_WW_Text.png";
import GetStartedButton from '../GetStartedButton/GetStartedButton';
import "./Showcase.css";

const Showcase = () => {

    const [headerImage, setHeaderImage] = useState(SmartCart_Comparison_WW_Text);

    const updateDimensions = () => {
        if (window.innerWidth <= 360) {
          setHeaderImage(SmartCart_Comparison_Mob02);
        } else if (window.innerWidth <= 600) {
          setHeaderImage(SmartCart_Comparison_Mob02);
        } else {
          setHeaderImage(SmartCart_Comparison_WW_Text);
        }
      };
    
      useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
      }, []);


    return (
        <div className="showcase">
          <div className="showcase-title">
            We'll tell you where your cart is cheaper
          </div>

          <div className="showcase-container">
            <img
              className="showcase-container-image"
              src={headerImage}
              alt=""
            />
          </div>
          <div className="showcase-title-bottom">
            <div className="greensock">
              Sick of wondering if you could have gotten a better deal
              elsewhere? we did too...
            </div>
          </div>
          <div className="showcase-install-button">
            <GetStartedButton />
          </div>
        </div>
    );
};

export default Showcase;