import React from 'react';
import { useDispatch } from 'react-redux';
import tickIcon from "../../assets/icons/Tick (1).svg";
import emptyIcon from "../../assets/icons/emptyTik.svg";
import { similarProductSelection } from '../../features/Cart/CartSlice';

const CartSimilarProduct = ({ pro, woolSimilar, colesSimilar, amazonSimilar, WooloworthsProduct, colesProduct, amazonProduct}) => {

    const dispatch = useDispatch();

    const handleSimilarProductClick = () => {
        if (colesSimilar) {
            dispatch(similarProductSelection({cartItemId: colesProduct.parentId ,selectedColesProduct:pro}));
        }
        else if (amazonSimilar){
            dispatch(similarProductSelection({cartItemId: amazonProduct.parentId ,selectedAmazonProduct:pro}));
        } 
        else if (woolSimilar){
            dispatch(similarProductSelection({cartItemId: WooloworthsProduct.parentId ,selectedWoolProduct:pro}));
        } 
      };

    //   console.log(amazonProduct);

      let isSimilar;

      if (pro?.candidateProductId === colesProduct?.candidateProductId) {
        isSimilar = true;
      }
      else if(pro?.candidateProductId === amazonProduct?.candidateProductId){
        isSimilar = true;
      }
      else if(pro?.candidateProductId === WooloworthsProduct?.candidateProductId){
        isSimilar = true;
      }
      else{
        isSimilar = false;
      }


    return (
        <>
            <div onClick={handleSimilarProductClick} className={`${isSimilar && "border border-[#02C39A]" } gap-4 w-36 h-60 pb-2 px-1 flex flex-col items-center justify-center  rounded-xl mt-2 bg-white cursor-grab shadow-md relative`}>
                <div className=''>
                    {
                       isSimilar ? <img className='absolute top-[5px] right-[5px]' src={tickIcon} alt="" /> : <img className='absolute top-[5px] right-[5px]' src={emptyIcon} alt="" />
                    }
                    <img className='w-36 pt-2 mx-auto rounded-xl max-h-40' src={pro?.candidateProductUrl} alt="" />
                    <h3 className='text-sm text-black text-center tracking-tight leading-[22px] '>
                        {/* Mayver's Crunchy  Natural Peanut Butter */}
                        {pro && pro.cadidateName?.slice(0, 25)}...
                        </h3>
                </div>
                <div className='flex justify-between w-full items-center'>
                    <p className='text-black text-sm font-bold'>${pro?.currentPrice}</p>
                    <p className='text-black text-xs'>375g</p>
                </div>
            </div>
        </>
    );
};

export default CartSimilarProduct;