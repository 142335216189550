import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "./Testimonial.css";

const Testimonial = () => {

    const suggestions = [
        {
          id: 1,
          story:
            '“Brilliant. Makes my life so much easier. Last week I saved over $30 on my shop. I don’t have to scroll through both sites to find everything”',
          username: 'Samara.'
        },
        { id: 2, story: '"Really hoping it will help to get my grocery budget under control!!"', username: 'Casey' },
        { id: 3, story: '"I downloaded it and love it"', username: 'Davina' },
        {
          id: 4,
          story: `“I can’t believe there's such a big difference between Coles and Woolies once I compared my entire shop”`,
          username: 'James'
        },
        {
          id: 5,
          story: `It's a brilliant idea!`,
          username: 'Sherry'
        },
    
        { id: 6, story: '"This is a game changer"', username: 'Sean. 27' },
        { id: 7, story: `"I'm saving $30 a week on my groceries with SmartCart. It's amazing!"`, username: 'Louisa. 33' }
      ]

      let settings = {
        customPaging: (i) => (
          <div
            style={{
              color: 'white',
              fontSize: '30px'
            }}
          >
            •
          </div>
        ),
        dots: true,
        infinite: true,
        autoplaySpeed: 5000,
        autoplay: true,
        pauseOnDotsHover: true,
        speed: 1000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }

    return (
        <div className="slider-container">
        <div className="container-title">What our customers are saying</div>

        {suggestions.length === 0 ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <Slider {...settings}>
            {suggestions.map((current) => (
              <div className="out" key={current.id}>
                <div className="card" style={{ width: '100%', height: '200px' }}>
                  <div className="card-body">
                    <small
                      className="card-text text-sm-center"
                      style={{ fontStyle: 'italic', fontSize: '18px', color: 'white' }}
                    >
                      {current.story}
                      <br />
                      <small style={{ fontStyle: 'normal', fontSize: '18px', color: 'white' }}>
                        {current.username}
                      </small>
                    </small>
                    <br />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
};

export default Testimonial;