import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTaxonomyProducts } from "../../../../features/AllProducts/AllProductsSlice";
import AllProductsLoading from "../../../Utils/SclatonLoadings/AllProductsLoading";
import ProductCart from "../../Product/ProductCart";

const Products = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const taxonomyId = parseInt(param.item?.split("_")[1]) || 875;
  const stateProducts = useSelector((state) => state.products.allProducts)
  const taxonomyProducts = useSelector((state) => state.products.taxonomyProducts)

  const isError = false
  const isSuccess = true
  const [isLoading, setIsLoading] = useState(false);


 useEffect(() => {
  if (taxonomyId && stateProducts.length > 0) {
    
    dispatch(getTaxonomyProducts({taxonomyId}))
  }
 }, [taxonomyId, dispatch, stateProducts])


  if (isLoading) {
    return <div className="flex items-center justify-center w-full h-full"> <AllProductsLoading /></div> 
  }

  return (
    <>
      {/* <div ref={scrollContainerRef} className=" z-40 p-4 overflow-x-hidden custom-scrollbar"> */}
      <div className=" z-40 p-4 overflow-x-hidden custom-scrollbar">
        <div className="p-4 rounded-lg">
          <div className="flex flex-wrap items-center justify-center mx-auto gap-2 mb-4 w-full">
            {isError && (
              <p className="m-2 text-center py-1 px-2 bg-red-400 rounded-md">
                Something went wrong! Please try again later.
              </p>
            )}  

            {isSuccess &&
              taxonomyProducts?.products?.length > 0 &&
              taxonomyProducts?.products?.map((product) => (
                <ProductCart
                  key={product.targetProductId}
                  product={product}
                ></ProductCart>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

