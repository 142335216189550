// import React from "react";
// import 'swiper/css';
// import "swiper/css/pagination";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import CartSimilarProduct from "../Utils/CartSimilarProduct";

// const SimilarCartCarousel = () => {
//   return (
//     <Swiper
//     slidesPerView={'auto'}
//         breakpoints={{
//         1024: {
//           slidesPerView: 3,
//         },
//         1280: {
//           slidesPerView: 3,
//         },
//       }}
//       pagination={{
//         clickable: true,
//       }}
//       // modules={[Pagination]}
//         className="mySwiper flax items-center justify-center pb-2"
//     >
//       <SwiperSlide>
//         <CartSimilarProduct></CartSimilarProduct>
//       </SwiperSlide>
//       <SwiperSlide>
//         <CartSimilarProduct selected={true}></CartSimilarProduct>
//       </SwiperSlide>
//       <SwiperSlide>
//         <CartSimilarProduct></CartSimilarProduct>
//       </SwiperSlide>
//       <SwiperSlide>
//         <CartSimilarProduct></CartSimilarProduct>
//       </SwiperSlide>
//       <SwiperSlide>
//         <CartSimilarProduct></CartSimilarProduct>
//       </SwiperSlide>
//       <SwiperSlide>
//         <CartSimilarProduct></CartSimilarProduct>
//       </SwiperSlide>
//       {/* Add more SwiperSlides for additional cards */}
//     </Swiper>
//   );
// };

// export default SimilarCartCarousel;

// --------------------------------

import React, { useEffect } from "react";
import Swiper from "swiper";
import "swiper/css/pagination";
import "swiper/swiper-bundle.min.css";
import CartSimilarProduct from "../Utils/CartSimilarProduct";

const SimilarCartCarousel = ({ woolsSimilarProduct, colesSimilarProduct, amazonSimilarProduct, WooloworthsProduct, colesProduct, amazonProduct }) => {


  useEffect(() => {
    new Swiper(".swiper-container", {
      slidesPerView: "auto",
      spaceBetween: -185,
      breakpoints: {
        // Modify the space between slides at different screen sizes
        390: {
          spaceBetween: -160,
        },
        1024: {
          spaceBetween: -80,
        },
        1280: {
          spaceBetween: -185,
        },
        // Add more breakpoints as needed
      },
      grabCursor: true,
    });
  }, []);

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper ">
        {woolsSimilarProduct?.map((pro) => {
          return (
            <div className="swiper-slide">
              <CartSimilarProduct pro={pro} WooloworthsProduct={WooloworthsProduct} woolSimilar={true}></CartSimilarProduct>
            </div>
          );
        })}
        {colesSimilarProduct?.map((pro) => {
          return (
            <div className="swiper-slide">
              <CartSimilarProduct pro={pro} colesProduct={colesProduct} colesSimilar={true}></CartSimilarProduct>
            </div>
          );
        })}
        {amazonSimilarProduct?.map((pro) => {
          return (
            <div className="swiper-slide">
              <CartSimilarProduct pro={pro} amazonProduct={amazonProduct}  amazonSimilar={true}></CartSimilarProduct>
            </div>
          );
        })}

        {/* <div className="swiper-slide">
          <CartSimilarProduct selected={true}></CartSimilarProduct>
        </div>
        <div className="swiper-slide">
          <CartSimilarProduct></CartSimilarProduct>
        </div>
        <div className="swiper-slide">
          <CartSimilarProduct></CartSimilarProduct>
        </div>
        <div className="swiper-slide">
          <CartSimilarProduct></CartSimilarProduct>
        </div>
        <div className="swiper-slide">
          <CartSimilarProduct></CartSimilarProduct>
        </div> */}
        {/* Add more slides for additional cards */}
      </div>
    </div>
  );
};

export default SimilarCartCarousel;
