import { apiSlice } from "../Api/ApiSlice";

export const getSingleProduct = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // products api
    getSingleProduct: builder.query({
        query: (productId) => `products/getProductsData?productId=${productId}`,
    
        // async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        //   try {
        //     const response = await queryFulfilled;
        //   } catch (error) {}
        // },
      }),
  }),
});

export const {useGetSingleProductQuery} = getSingleProduct;