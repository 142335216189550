import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../../features/Utils/SidebarOnOffSlice";
import Dropdown from "../Sidebar/Dropdown";

const Sidebar = ({className }) => {
  // const { data, isLoading } = useGetAllCategoryQuery();
  const [data, setDate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const isSidebarOn = useSelector(state => state.SidebarStatus.IsSidebarOn);

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);


  useEffect(() => {
    fetch("Categories.json")
      .then((response) => response.json())
      .then((data) => setDate(data), setIsLoading(false));   
  }, []);


  const handleDropdownClick = (index) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(null); // Close the dropdown if already open
    } else {
      setOpenDropdownIndex(index); // Open the clicked dropdown
    }
  };

  // Generate the dropdown items for each category
  const dropdown = isLoading ? <h1>loading...</h1> : data?.map((category, index) => (
    <Dropdown
      key={index}
      mainTitle={category.category}
      mainItems={category.subcategories}
      isOpen={openDropdownIndex === index}
      onDropdownClick={() => handleDropdownClick(index)}
    ></Dropdown>
  ));

  return (
    <>
      <aside
        className={`${
          isSidebarOn
            ? `translate-x-0  ${className}`
            : "-translate-x-full absolute top-0 left-0 transition-transform duration-300 ease-in-out transform"
        }  h-full md:w-[425px]  w-5/6  max-h-screen overflow-y-scroll bg-gray-200 transition-transform duration-300 ease-in-out transform rounded-lg z-30 `}
      >
        <div class=" px-3 py-4 bg-gray-50 dark:bg-gray-800">
          <ul class="space-y-2 font-medium flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <h3 className="text-2xl font-bold">Categories </h3>
              <svg
                onClick={() => dispatch(toggleSidebar())}
                className=""
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="black"
                />
              </svg>
            </div>
            {/* <p className="text-gray-400 font-light">MAIN</p> */}

            {/* -------------------------- */}
            { dropdown }
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
